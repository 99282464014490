import axios from "axios";
import { Activity } from "../interfaces/Activity";
import { formatDateForBackend } from "../functions/dateHelper";
import { gql } from '@apollo/client';
import client from '../apollo-client';

const API_URL = process.env.REACT_APP_GRAPHQL_URI!;

export class ActivityService {
    async getActivities(clientId: number|null = null, type : number): Promise<Activity[]> {
        const response = await client.query({
            query: gql`
                query {
                    activities${ clientId ? `(type: ${type}, where: {clientId: ${clientId}})` : `(type: ${type})` } {
                        id
                        client {
                            company
                        }
                        user {
                            id
                            name
                        }
                        clientId
                        userId
                        createdBy
                        priority
                        scheduledDate
                        scheduledContact
                        contact {
                            name
                        }
                        contactId
                        description
                        closureReason
                        status
                        ordered
                    }
                }
            `,
        });
        return response.data.activities;
    }

    async find(id: number): Promise<Activity> {
        const response = await axios.post(API_URL, {
            query: `
                query {
                    activity(id: ${id}) {
                        id
                        client {
                            company
                        }
                        user {
                            name
                        }
                        clientId
                        userId
                        createdBy
                        priority
                        scheduledDate
                        scheduledContact
                        contact {
                            name
                        }
                        contactId
                        description
                        closureReason
                        status
                        ordered
                    }
                }
            `,
        });
        const activity = response.data.data.activity;
        return { ...activity, scheduledDate: activity.scheduledDate ? new Date(activity.scheduledDate) : null };
    }

    async create(activity: Activity): Promise<Activity> {
        const response = await client.mutate({
            mutation: gql`
                mutation createActivity($input: CreateActivityDto!){
                    createActivity(input: $input){
                        id
                        client {
                            company
                        }
                        user {
                            id
                            name
                        }
                        clientId
                        userId
                        createdBy
                        priority
                        scheduledDate
                        scheduledContact
                        contact {
                            name
                        }
                        contactId
                        description
                        closureReason
                        status
                        ordered
                    }
                }
            `,
            variables: {
              input: {
                clientId: activity.clientId,
                userId: activity.userId,
                priority: activity.priority,
                scheduledDate: formatDateForBackend(activity.scheduledDate),
                scheduledContact: activity.scheduledContact,
                contactId: activity.contactId,
                description: activity.description
              }
            }
        });
        return response.data.createActivity;
    }

    async update(activity: Activity): Promise<Activity> {
        const response = await client.mutate({
            mutation: gql`
                mutation updateActivity($input: UpdateActivityDto!){
                    updateActivity(input: $input){
                        id
                        client {
                            company
                        }
                        user {
                            id
                            name
                        }
                        clientId
                        userId
                        createdBy
                        priority
                        scheduledDate
                        scheduledContact
                        contact {
                            name
                        }
                        contactId
                        description
                        closureReason
                        status
                        ordered
                    }
                }
            `,
            variables: {
              input: {
                id: activity.id,
                ordered: activity.ordered,
                priority: activity.priority,
                scheduledDate: formatDateForBackend(activity.scheduledDate),
                scheduledContact: activity.scheduledContact,
                contactId: activity.contactId,
                description: activity.description
              }
            }
        });

        if (response.errors) {
            throw new Error(`GraphQL error: ${response.errors.map((error: any) => error.message).join(', ')}`);
        }
        
        return response.data.updateActivity;
    }
    
    async closeActivityWithReason(id: number, closureReason: string|null): Promise<Activity> {
        const response = await client.mutate({
            mutation: gql`
                mutation updateActivity($input: UpdateActivityDto!){
                    updateActivity(input: $input){
                        id
                        client {
                            company
                        }
                        user {
                            name
                        }
                        clientId
                        userId
                        createdBy
                        priority
                        scheduledDate
                        scheduledContact
                        contact {
                            name
                        }
                        contactId
                        description
                        closureReason
                        status
                        ordered
                    }
                }
            `,
            variables: {
              input: {
                id: id,
                status: 'Închis',
                closureReason: closureReason ? closureReason : null,
              }
            }
        });

        if (response.errors) {
            throw new Error(`GraphQL error: ${response.errors.map((error: any) => error.message).join(', ')}`);
        }

        return response.data.updateActivity;
    }

    async createFromExisting(activity: Activity, id : number): Promise<Activity[]> {
        const response = await client.mutate({
            mutation: gql`
                mutation createActivityFromExisting($input: UpdateAndCreateActivity!){
                    createActivityFromExisting(input: $input){
                        id
                        client {
                            company
                        }
                        user {
                            name
                        }
                        clientId
                        userId
                        createdBy
                        priority
                        scheduledDate
                        scheduledContact
                        contact {
                            name
                        }
                        contactId
                        description
                        closureReason
                        status
                        ordered
                    }
                }
            `,
            variables: {
              input: {
                id: id,
                userId: activity.userId,
                priority: activity.priority ? activity.priority : null,
                scheduledDate: formatDateForBackend(activity.scheduledDate),
                scheduledContact: activity.scheduledContact ? activity.scheduledContact : null,
                contactId: activity.contactId != undefined ? activity.contactId : null,
                description: activity.description ? activity.description : null,
              }
            }
        });

        if (response.errors) {
            throw new Error(`GraphQL error: ${response.errors.map((error: any) => error.message).join(', ')}`);
        }

        return response.data.createActivityFromExisting;
    }

    async deleteActivity(id: number): Promise<boolean> {
        const response = await axios.post(API_URL, {
          query: `
            mutation {
              deleteActivity(id: ${id})
            }
          `,
        });
        return response.data.data.deleteActivity;
      }
}
