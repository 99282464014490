import React, { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Tooltip } from "flowbite-react";
import { Vessel } from '../../../interfaces/Vessel';
import { BookingService } from '../../../services/BookingService';
import renderSkeleton from '../../../components/SkeletonLoader';
import Table from './Table';
import { EmailQueue } from '../../../interfaces/Booking';

const Index: React.FC = () => {
  const [emails, setEmails] = useState<Partial<EmailQueue>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const bookingService = new BookingService();

  useEffect(() => {
    fetchEmails();
  }, []);

  const fetchEmails = useCallback(
    async () => {
        setLoading(true);
        bookingService.getEmailQueue().then((data) => { setEmails(data); setLoading(false)}).catch(() => {setLoading(false)});
  },[])
  
  return (
    <div className="ag-theme-quartz px-4">
        <div className="flex justify-between items-center py-2">
            <div className="text-lg font-bold">
                Emailuri
            </div>
        </div>
        {loading ? ( renderSkeleton() ) : (
            <>
                <Table emails={emails} />
            </>
        )}
    </div>
  );
};

export default Index;