import { Type } from 'class-transformer';
import { IsDate, IsInt, IsNotEmpty, IsOptional, IsString, MaxLength } from 'class-validator';

export class CreateBookingBillBooking {
    @IsNotEmpty({ message: 'Tabul B/L\'s: Câmpul "Număr" este obligatoriu' })
    @IsString({ message: 'Tabul B/L\'s: Câmpul "Număr" trebuie să fie un șir de caractere' })
    @MaxLength(255, { message: 'Tabul B/L\'s: Câmpul "Număr" nu poate avea mai mult de 255 de caractere' })
    code: string;

    @IsOptional()
    @IsDate({ message: 'Tabul B/L\'s: Câmpul "Dată" trebuie să fie o dată validă' })
    @Type(() => Date)
    date?: Date|null;

    @IsOptional()
    @IsString({ message: 'Tabul B/L\'s: Câmpul "Terminal" trebuie să fie un șir de caractere' })
    @MaxLength(255, { message: 'Tabul B/L\'s: Câmpul "Terminal" nu poate avea mai mult de 255 de caractere' })
    terminal?: string;

    @IsOptional()
    @IsString({ message: 'Tabul B/L\'s: Câmpul "Tip eliberare" trebuie să fie un șir de caractere' })
    @MaxLength(255, { message: 'Tabul B/L\'s: Câmpul "Tip eliberare" nu poate avea mai mult de 255 de caractere' })
    releaseType?: string;

    @IsOptional()
    @IsString({ message: 'Tabul B/L\'s: Câmpul "Nume shipper" trebuie să fie un șir de caractere' })
    @MaxLength(255, { message: 'Tabul B/L\'s: Câmpul "Nume shipper" nu poate avea mai mult de 255 de caractere' })
    shipperName?: string;

    @IsOptional()
    @IsString({ message: 'Tabul B/L\'s: Câmpul "Adresă shipper" trebuie să fie un șir de caractere' })
    @MaxLength(255, { message: 'Tabul B/L\'s: Câmpul "Adresă shipper" nu poate avea mai mult de 255 de caractere' })
    shipperAddress?: string;

    @IsOptional()
    @IsString({ message: 'Tabul B/L\'s: Câmpul "Nume consingee" trebuie să fie un șir de caractere' })
    @MaxLength(255, { message: 'Tabul B/L\'s: Câmpul "Nume consingee" nu poate avea mai mult de 255 de caractere' })
    consigneeName?: string;

    @IsOptional()
    @IsString({ message: 'Tabul B/L\'s: Câmpul "Adresă consignee" trebuie să fie un șir de caractere' })
    @MaxLength(255, { message: 'Tabul B/L\'s: Câmpul "Adresă consignee" nu poate avea mai mult de 255 de caractere' })
    consigneeAddress?: string;

    @IsOptional()
    @IsString({ message: 'Tabul B/L\'s: Câmpul "HS Code" trebuie să fie un șir de caractere' })
    @MaxLength(255, { message: 'Tabul B/L\'s: Câmpul "HS Code" nu poate avea mai mult de 255 de caractere' })
    hsCode?: string;

    @IsOptional()
    @IsString({ message: 'Tabul B/L\'s: Câmpul "Loc încărcare" trebuie să fie un șir de caractere' })
    @MaxLength(255, { message: 'Tabul B/L\'s: Câmpul "Loc încărcare" nu poate avea mai mult de 255 de caractere' })
    loadingPlace?: string;

    @IsOptional()
    @IsString({ message: 'Tabul B/L\'s: Câmpul "Marfă" trebuie să fie un șir de caractere' })
    @MaxLength(255, { message: 'Tabul B/L\'s: Câmpul "Marfă" nu poate avea mai mult de 255 de caractere' })
    commodity?: string;
}

export type BookingBillCreateForm = InstanceType<typeof CreateBookingBillBooking>;

export class UpdateBookingBillBooking extends CreateBookingBillBooking {
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type BookingBillUpdateForm = InstanceType<typeof UpdateBookingBillBooking>;