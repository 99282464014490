import React, { useEffect } from 'react';
import { Modal } from 'flowbite-react';
import { Email } from '../../../interfaces/Offer';

interface EmailContentModalProps {
  show: boolean;
  onClose: () => void;
  email: Email | null;
}

const EmailContentModal: React.FC<EmailContentModalProps> = ({ show, onClose, email }) => {
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .gmail-content {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
        line-height: 1.5;
        color: #222;
      }
      .gmail-content img {
        max-width: 100%;
        height: auto;
      }
      .gmail-content table {
        max-width: 100%;
      }
    `;
    document.head.appendChild(style);
    
    // Important: returnăm o funcție de cleanup, nu elementul style
    return () => {
      document.head.removeChild(style);
    };
  }, []);

    if (!email) return null;

  return (
    <Modal
      show={show}
      onClose={onClose}
      size="7xl"
      dismissible
    >
      <Modal.Header>
        {email.subject}
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-4">
          <div 
            className="gmail-content"
            dangerouslySetInnerHTML={{ 
              __html: email.body
            }} 
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EmailContentModal;