import React, { useState, useEffect, useCallback } from 'react';
import { BookingBillTable } from '../../../interfaces/Booking';
import { Button, Dropdown, Tooltip } from "flowbite-react";
import { BookingService } from '../../../services/BookingService';
import renderSkeleton from '../../../components/SkeletonLoader';
import Table from './Table';
import ArrivalNoticeModal from '../components/ArrivalNoticeModal';
import ModalImport from './ModalImport';

const Index: React.FC = () => {
  const [bills, setBills] = useState<Partial<BookingBillTable>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showArrivalNoticeModal, setShowArrivalNoticeModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const bookingService = new BookingService();
  const [documentType, setDocumentType] = useState<string>('');

  useEffect(() => {
    fetchBills();
  }, []);

  const fetchBills = useCallback(
    async () => {
        setLoading(true);
        bookingService.getBills().then((data) => { setBills(data); setLoading(false)}).catch(() => {setLoading(false)});
  },[])

  return (
    <div className="ag-theme-quartz px-4">
      <div className="flex justify-between items-center py-2">
      <div className="text-lg font-bold">
        B/L's
      </div>
    
      <div className="flex gap-2">
        <Tooltip content="Importă B/L-uri">
          <Button size="sm" onClick={() => {setShowImportModal(true)}}>Importă B/L-uri</Button>
        </Tooltip>
        <Dropdown label="Trimite documente" size="sm">
          <Dropdown.Item onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('arrivalNotice')}}>Preavizare</Dropdown.Item>
          <Dropdown.Item onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('arrivalUpdate')}}>Avizare</Dropdown.Item>
        </Dropdown>
      </div>

    </div>
      {loading ? ( renderSkeleton() ) : (
        <>
          <Table bills={bills} />
          <ArrivalNoticeModal setDocumentType={setDocumentType} documentType={documentType} type="vessel" showModal={showArrivalNoticeModal} setShowModal={setShowArrivalNoticeModal} />
          <ModalImport showModal={showImportModal} setShowModal={setShowImportModal} onSuccess={fetchBills} />
        </>
      )}
    </div>
  );
};

export default Index;