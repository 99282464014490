import React, { useEffect, useState } from 'react';
import Input from "../../../components/Input";
import CustomSelect from "../../../components/CustomSelect";
import CustomDatePicker from "../../../components/CustomDatePicker";
import InputSug from "../../../components/InputSug";
import { handleInputChange, handleSelectChange } from "../../../functions/selectHelper";
import { bookingStatuses, bookingStatusesExport } from "../../../interfaces/Booking";
import { LabelValueInt, RecommendationResp } from "../../../interfaces/Recommandation";
import { getUsersForSelect, handleOptions, handleRecommendations } from '../../../functions/generalHelper';
import { formatDate, getDateFormat } from '../../../functions/dateHelper';
import { OfferService } from '../../../services/OfferService';
import { BookingService } from '../../../services/BookingService';
import DynamicPortSelect from '../../../components/DynamicPortSelect';
import TextArea from '../../../components/Textarea';
import { BookingUpdateForm } from '../../../validations/booking/CreateBooking';

interface TabProps {
  booking: Partial<BookingUpdateForm>;
  setBooking: React.Dispatch<React.SetStateAction<Partial<BookingUpdateForm>>>;
  recommendations: RecommendationResp[];
}

const GeneralTab: React.FC<TabProps> = ({ booking, setBooking, recommendations }) => {
  const statusOptions = bookingStatuses.map(item => ({label: item.name, value: item.name}));
  const statusOptionsExport = bookingStatusesExport.map(item => ({label: item.name, value: item.name}));
  const shippingTypeOptions = handleOptions(recommendations, 'booking-shippingType');
  const loadTypeOptions = handleOptions(recommendations, 'booking-loadType');
  const bookingTypeOptions = handleOptions(recommendations, 'booking-bookingType');
  const shippingLineSuggestions = handleRecommendations(recommendations, 'booking-shippingLineName');
  const blockStatusSuggestions = handleRecommendations(recommendations, 'booking-blockStatus');
  const bookingWaitingOptions = handleOptions(recommendations, 'booking-waiting');
  const [userOptions, setUserOptions] = useState<LabelValueInt[]>([]);
  const svcTermOriginOptions = handleOptions(recommendations, 'container-svcTermOrigin');
  const svcTermDestinationOptions = handleOptions(recommendations, 'container-svcTermDestination');
  const offerService = new OfferService();
  const bookingService = new BookingService();
  const [offerOptions, setOfferOptions] = useState<LabelValueInt[]>([]);
  const [clientOptions, setClientOptions] = useState<LabelValueInt[]>([]);

  useEffect(() => {
    getUsersForSelect().then(users => setUserOptions(users));
    bookingService.getClients().then((clients) => setClientOptions(clients.map((client) => ({value: client.id!, label: client.company}))));
  }, []);

  useEffect(() => {
    if(booking.clientId){
      offerService.getOffersForBooking(booking.clientId).then(
        (data) =>
          setOfferOptions(
            data.map((offer) => ({ value: offer.id!, label: formatDate(offer.validityStartDate!)! }))
          )
      );
    }
    getUsersForSelect().then(users => setUserOptions(users));
  }, [booking.clientId]);

  useEffect(() => {
    if(booking.shippingType === 'Export CIF' || booking.shippingType === 'Export FOB'){
      setBooking(prev => ({...prev, pol: 'ROCND', status: 'Creat'}));
      if(booking.pod === 'ROCND'){
        setBooking(prev => ({...prev, pod: null, status: 'Creat'}));
      }
    }else{
      setBooking(prev => ({...prev, pod: 'ROCND', status: 'Confirmat'}));
      if(booking.pol === 'ROCND'){
        setBooking(prev => ({...prev, pol: null, status: 'Confirmat'}));
      }
    }
  }, [booking.shippingType]);

  return (
    <div>
      <div className="rounded-lg border border-gray-200 bg-white p-5 relative mb-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Date Booking</h4>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
          <Input 
            id="number" 
            label="Numar de booking"
            value={booking.number}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'number', e.target.value, {} as BookingUpdateForm)}
          />
          {
            booking.shippingType === 'Export CIF' || booking.shippingType === 'Export FOB' ?
            <>
              <CustomSelect
                value={statusOptionsExport.find(option => option.value === booking.status)}
                onChange={(data) => handleSelectChange(booking, setBooking, 'status', data)}
                options={statusOptionsExport}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Status"
              />
            </> : 
            <>
              <CustomSelect
                value={statusOptions.find(option => option.value === booking.status)}
                onChange={(data) => handleSelectChange(booking, setBooking, 'status', data)}
                options={statusOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Status"
              />
            </>
          }

          <CustomSelect
            value={shippingTypeOptions.find(option => option.value === booking.shippingType)}
            onChange={(data) => handleSelectChange(booking, setBooking, 'shippingType', data)}
            options={shippingTypeOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Tipul transport"
          />
          <CustomSelect
            value={loadTypeOptions.find(option => option.value === booking.loadType)}
            onChange={(data) => handleSelectChange(booking, setBooking, 'loadType', data)}
            options={loadTypeOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Tipul de incarcare"
          />
          <CustomSelect
            value={bookingTypeOptions.find(option => option.value === booking.bookingType)}
            onChange={(data) => handleSelectChange(booking, setBooking, 'bookingType', data)}
            options={bookingTypeOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Tipul bookingului"
          />
          <InputSug
            id="shippingLineName"
            label="Linie maritimă"
            value={booking.shippingLineName}
            suggestions={shippingLineSuggestions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'shippingLineName', e.target.value, {} as BookingUpdateForm)}
          />
          {/* <CustomSelect
            value={viaOptions.find(option => option.value === booking.via)}
            onChange={(data) => handleSelectChange(booking, setBooking, 'via', data)}
            options={viaOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Sursă booking"
          /> */}
          {/* <InputSug
            id="office"
            label="Oficiu"
            value={booking.office}
            suggestions={officeSuggestions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'office', e.target.value, {} as Booking)}
          /> */}
          {/* <InputSug
            id="chinaAgent"
            label="Agentul din China"
            value={booking.chinaAgent}
            suggestions={chinaAgentSuggestions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'chinaAgent', e.target.value, {} as Booking)}
          /> */}
          {/* <CustomSelect
            value={tradeOptions.find(option => option.value === booking.trade)}
            onChange={(data) => handleSelectChange(booking, setBooking, 'trade', data)}
            options={tradeOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Trade"
          /> */}
          {/* <Input 
            id="commodity" 
            label="Tip marfă"
            value={booking.commodity}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'commodity', e.target.value, {} as Booking)}
          /> */}
          <CustomDatePicker
            selected={booking.creationDate ? new Date(booking.creationDate) : null}
            onChange={(date) => setBooking({ ...booking, creationDate: date })}
            dateFormat={getDateFormat()}
            locale="ro"
            isClearable
            placeholderText="Data bookingului"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />

          {/* <Input 
            id="blockStowage" 
            label="Blocarea încărcării"
            value={booking.blockStowage}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'blockStowage', e.target.value, {} as Booking)}
          /> */}
          {/* <ToggleSwitch
            color="primary"
            checked={booking.rated}
            label="Rated"
            onChange={() => setBooking({ ...booking, rated: !booking.rated })}
          /> */}
          {
            booking.status === 'Anulat' ? <>
              <Input 
                id="cancelReason" 
                label="Motivul anulării"
                value={booking.cancelReason}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'cancelReason', e.target.value, {} as BookingUpdateForm)}
              />
              <CustomDatePicker
                selected={booking.cancelledDate ? new Date(booking.cancelledDate) : null}
                onChange={(date) => setBooking({ ...booking, cancelledDate: date })}
                dateFormat={getDateFormat()}
                locale="ro"
                isClearable
                placeholderText="Data anulării"
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </> : <></>
          }
          {
            booking.status === 'Blocat' ? <>
              <InputSug
                id="blockStatus"
                label="Motiv blocare"
                value={booking.blockStatus}
                suggestions={blockStatusSuggestions}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'blockStatus', e.target.value, {} as BookingUpdateForm)}
              />
            </> : <></>
          }
          {
            booking.status === 'În așteptare' ? <>
              <CustomSelect
                value={bookingWaitingOptions.find(option => option.value === booking.waiting)}
                onChange={(data) => handleSelectChange(booking, setBooking, 'waiting', data)}
                options={bookingWaitingOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Motiv așteptare"
              />
            </> : <></>
          }
        </div>
      </div>

      <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Date transport</h4>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
          <Input 
            id="loadingPlace" 
            label="Loc de încărcare"
            value={booking.loadingPlace}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'loadingPlace', e.target.value)}
          />
          <DynamicPortSelect
            placeholder="POL"
            value={booking.pol ? booking.pol : ''}
            onChange={(newValue) => setBooking(prev => ({...prev, pol: newValue.value}))}
          />
          <DynamicPortSelect
            placeholder="POD"
            value={booking.pod ? booking.pod : ''}
            onChange={(newValue) => setBooking(prev => ({...prev, pod: newValue.value}))}
          />
          <Input 
            id="hsCode" 
            label="HS Code"
            value={booking.hsCode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'hsCode', e.target.value)}
          />
          <TextArea 
            id={`commodity`}
            label="Marfă transportată"
            value={booking.commodity}
            rows={1}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(booking, setBooking, 'commodity', e.target.value)}
          />
          <TextArea 
            id={`note`}
            label="Notă"
            value={booking.note}
            rows={1}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(booking, setBooking, 'note', e.target.value)}
          />
          <CustomSelect
            value={svcTermOriginOptions.find(option => option.value === booking.svcTermOrigin)}
            onChange={(data) => handleSelectChange(booking, setBooking, 'svcTermOrigin', data)}
            options={svcTermOriginOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="SVC Term Origin"
          />
          <CustomSelect
            value={svcTermDestinationOptions.find(option => option.value === booking.svcTermDestination)}
            onChange={(data) => handleSelectChange(booking, setBooking, 'svcTermDestination', data)}
            options={svcTermDestinationOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="SVC Term Destination"
          />
        </div>
      </div>

      <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Date transportator</h4>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
          <Input 
            id="shipperName" 
            label="Nume Shipper"
            value={booking.shipperName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'shipperName', e.target.value)}
          />
          <Input 
            id="shipperAddress" 
            label="Adresa Shipper"
            value={booking.shipperAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'shipperAddress', e.target.value)}
          />
          <Input 
            id="consigneeName" 
            label="Nume Consignee"
            value={booking.consigneeName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'consigneeName', e.target.value)}
          />
          <Input 
            id="consigneeAddress" 
            label="Adresa Consignee"
            value={booking.consigneeAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'consigneeAddress', e.target.value)}
          />
          <CustomSelect
              value={clientOptions.find(client => client.value === booking.clientId)}
              onChange={(data) => handleSelectChange(booking, setBooking, 'clientId', data)}
              options={clientOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Client"
          />
          <CustomSelect
              value={offerOptions.find(offer => offer.value === booking.offerId)}
              onChange={(data) => handleSelectChange(booking, setBooking, 'offerId', data)}
              options={offerOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Ofertă"
          />
          <CustomSelect
            value={userOptions.find(user => user.value === booking.createdBy)}
            onChange={(data) => handleSelectChange(booking, setBooking, 'createdBy', data)}
            options={userOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Sales"
          />
        </div>
      </div>
      
      {/* <div className="rounded-lg border border-gray-200 bg-white p-5 relative mb-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Forwarder</h4>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
          <div className="mb-2">
            <Input 
              id={`forwarderName`}
              label="Nume"
              value={booking.forwarderName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'forwarderName', e.target.value, {} as Booking)}
            />
          </div>
          <div className="mb-2">
            <Input 
              id={`forwarderAddress`}
              label="Adresă"
              value={booking.forwarderAddress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'forwarderAddress', e.target.value, {} as Booking)}
            />
          </div>
          <div className="mb-2">
            <Input 
              id={`forwarderPhone`}
              label="Numărul de telefon"
              value={booking.forwarderPhone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'forwarderPhone', e.target.value, {} as Booking)}
            />
          </div>
          <div className="mb-2">
            <Input 
              id={`forwarderEmail`}
              label="Email"
              value={booking.forwarderEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(booking, setBooking, 'forwarderEmail', e.target.value, {} as Booking)}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default GeneralTab;