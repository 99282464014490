import React, { useState, useEffect, useCallback, memo } from 'react';
import { Props as SelectProps, SingleValue, ActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface FloatingLabelSelectProps extends Omit<SelectProps, 'onChange'> {
  placeholder: string;
  onChange?: (newValue: SingleValue<any>, actionMeta: ActionMeta<any>) => void;
}

const CustomCreatable: React.FC<FloatingLabelSelectProps> = memo(({ placeholder, onChange, value, ...props }) => {
  const [isFilled, setIsFilled] = useState(false);
  const [isFocused, setIsFocused] = useState(false); 

  useEffect(() => {
    setIsFilled(Array.isArray(value) ? value.length > 0 : !!value);
  }, [value]);

  const handleChange = useCallback((newValue: SingleValue<unknown>, actionMeta: ActionMeta<unknown>) => {
    setIsFilled(!!newValue);
    onChange?.(newValue, actionMeta);
  }, [onChange]);

  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur = useCallback(() => setIsFocused(false), []);

  return (
    <div className="floating-label-select">
      <CreatableSelect
        {...props}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        value={value}
      />
      <label className={`floating-label ${isFilled ? 'filled' : ''} ${isFocused ? 'focused' : ''}`}>
        {placeholder}
      </label>
    </div>
  );
});

CustomCreatable.displayName = 'CustomCreatable';

export default CustomCreatable;