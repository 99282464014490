import React from 'react';
import { Button } from 'flowbite-react';
import { FaPlus } from 'react-icons/fa6';
import Input from "../../../components/Input";
import InputSug from "../../../components/InputSug";
import { defaultOfferElement, Offer } from '../../../interfaces/Offer';
import Tooltip from '../../../components/Tooltip';
import { MdDeleteOutline } from 'react-icons/md';
import CustomSelect from '../../../components/CustomSelect';
import DynamicSearchSelect from '../../../components/DynamicPortSelect';
import { LabelValueString, RecommendationResp } from '../../../interfaces/Recommandation';
import DynamicPortSelect from '../../../components/DynamicPortSelect';
import { FaAngleDoubleLeft, FaAngleDoubleUp, FaRegCopy } from 'react-icons/fa';
import { handleOptions } from '../../../functions/generalHelper';

interface NavluSectionProps {
    offer: Offer;
    setOffer: React.Dispatch<React.SetStateAction<Offer>>;
    recommendations: RecommendationResp[];
}

const NavluSection: React.FC<NavluSectionProps> = ({ offer, setOffer, recommendations }) => {
    const containerOptions = handleOptions(recommendations, 'offer-containers');

    const handleShippingChange = (
        value: LabelValueString,
        rowIndex: number,
        field : string,
    ) => {
        setOffer(prevOffer => ({
            ...prevOffer,
            offerElements: prevOffer.offerElements.map((element, elementIndex) => {
                if (elementIndex === rowIndex) {
                    if(field == 'shippingFrom'){
                        return { ...element, shippingFrom: value.value };
                    }if(field == 'shippingTo'){
                        return { ...element, shippingTo: value.value };
                    }else{
                        return { ...element, name: value.label };
                    }
                }
                return element;
            })
        }));
    };

    const handleCurrentInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        rowIndex: number,
        colIndex: number
    ) => {
        const { name, value } = e.target;
        setOffer(prevOffer => ({
            ...prevOffer,
            offerElements: prevOffer.offerElements.map((element, elementIndex) => {
                if (elementIndex === rowIndex) {
                    const newPrices = [...element.prices];
                    newPrices[colIndex] = parseFloat(value);
                    return { ...element, prices: newPrices };
                }
                return element;
            })
        }));
    };

    const addRow = (type : string) => {
        setOffer((prevOffer) => ({
            ...prevOffer,
            offerElements: [...prevOffer.offerElements, defaultOfferElement(type, prevOffer.containers.length)]
        }));
    };

    const deleteRow = (rowIndex : number) => {
        setOffer((prevOffer) => ({
            ...prevOffer,
            offerElements: prevOffer.offerElements.filter((_, i) => i !== rowIndex)
        }));
    };

    const copyRow = (rowIndex: number) => {
        setOffer((prevOffer) => {
            const newElements = [...prevOffer.offerElements];
            // Creăm o copie profundă a elementului pe care vrem să-l duplicăm
            const elementToCopy = JSON.parse(JSON.stringify(newElements[rowIndex]));
            // Inserăm copia imediat după elementul original (rowIndex + 1)
            newElements.splice(rowIndex + 1, 0, elementToCopy);
            
            return {
                ...prevOffer,
                offerElements: newElements
            };
        });
    };

    const addColumn = () => {
        setOffer((prevOffer) => ({
            ...prevOffer,
            containers: [...prevOffer.containers, ""],
            offerElements: prevOffer.offerElements.map(element => ({
                ...element,
                prices: [...element.prices, null]
            }))
        }));
    };

    const deleteColumn = (colIndex: number) => {
        setOffer(prevOffer => ({
            ...prevOffer,
            containers: prevOffer.containers.filter((_, i) => i !== colIndex),
            offerElements: prevOffer.offerElements.map(element => ({
                ...element,
                prices: element.prices.filter((_, i) => i !== colIndex)
            }))
        }));
    }

    const handleSelectContainer = (container : any, colIndex : number) => {
        setOffer(prevOffer => ({
            ...prevOffer,
            containers: prevOffer.containers.map((element, elementIndex) => {
                if (elementIndex === colIndex) {
                    return container.value;
                }
                return element;
            })
        }));
    }

    // fa mi o functie care muta preturile pe un container, si containerul la stanga cu o pozitie. Ca argument primeste indexul containerului in offer.containers, si trebuie sa mute containerul cu o pozitie mai la stanga, dar trebuie sa mute si preturile din offerElements.prices[] care corespund containerului respectiv cu o pozitie mai la stanga(doar pentru elementele care au offerElements.prices.length > 0 si offerElements.type == navlu sau locale)
    const moveColumnLeft = (colIndex: number) => {
        setOffer((prevOffer) => {
            const newContainers = [...prevOffer.containers];
            newContainers.splice(colIndex - 1, 0, newContainers.splice(colIndex, 1)[0]);
            const newElements = prevOffer.offerElements.map(element => {
                if (element.type === 'navlu' || element.type === 'locale') {
                    const newPrices = [...element.prices];
                    newPrices.splice(colIndex - 1, 0, newPrices.splice(colIndex, 1)[0]);
                    return { ...element, prices: newPrices };
                }
                return element;
            });

            return {
                ...prevOffer,
                containers: newContainers,
                offerElements: newElements
            };
        });
    }

    return (
        <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-2 md:my-6">
            <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Navlu</h4>
            <div className="py-4">
                <table className="text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-auto md:table block">
                    <thead className="text-sm text-gray-700 dark:bg-gray-700 dark:text-gray-400 md:table-header-group block">
                        <tr className="md:table-row flex flex-col">
                            <th className="text-center min-w-[300px]"><span className="hidden md:block">Rută</span><span className="block md:hidden">Tipuri de Containere</span></th>
                            {offer.containers.map((name, colIndex) => (
                                <th key={`container${colIndex}`} className="w-auto min-w-[120px] px-2 md:border-s border-gray-300 mt-2 md:mt-0">
                                    <div className="grid grid-cols-[1fr_auto]">
                                        <div>
                                            <CustomSelect
                                                value={containerOptions.filter((container) => container.value == offer.containers[colIndex])}
                                                onChange={(data) => { handleSelectContainer(data, colIndex) }}
                                                options={containerOptions}
                                                placeholder="Tip container"
                                            />
                                        </div>
                                        <div className="grid items-center pl-2 md:hidden">
                                            <button type="button" onClick={() => {deleteColumn(colIndex)}} className="text-red-500 hover:text-red-700 h-[20px] flex items-center mx-1" aria-label={`Șterge tip container`}>
                                                <MdDeleteOutline className='w-[20px] h-[20px] hover:scale-110 duration-75 mb-1'/>
                                            </button>
                                            {
                                                colIndex !== 0 ? 
                                                <>
                                                    <button type="button" onClick={() => {moveColumnLeft(colIndex)}} className="text-blue-500 hover:text-blue-700 h-[20px] flex items-center mx-1" aria-label={`Mută la stânga`}>
                                                        <FaAngleDoubleUp className='w-[20px] h-[20px] hover:scale-110 duration-75 mt-1'/>
                                                    </button>
                                                </> : <></>
                                            }
                                        </div>
                                    </div>
                                </th>
                            ))}
                            <th className="ml-2 md:ml-0 my-2 md:my-0">
                                <Button onClick={addColumn}>   
                                    <FaPlus size={12}/>
                                </Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="md:w-full block md:table-row-group">
                        {offer.offerElements.map((element, rowIndex) => (
                            element.type === 'navlu' ? (                                
                                <tr key={`navlurow${rowIndex}`} className="bg-white border-b-0 md:border-b border-t md:border-t-0 dark:bg-gray-800 dark:border-gray-700 md:table-row flex flex-col mb-4">
                                    <td className="grid grid-cols-2 space-x-2 px-2 py-3 port-select-navlu-offer">
                                        <div>
                                            <div className="md:hidden font-bold mb-1">POL</div>
                                            <DynamicPortSelect
                                                placeholder="Port înmb."
                                                value={element.shippingFrom ? element.shippingFrom : ''}
                                                onChange={(newValue) => handleShippingChange(newValue!, rowIndex, 'shippingFrom')}
                                                creatable={offer.type === 'Export'}
                                            />
                                        </div>
                                        <div>
                                            <div className="md:hidden font-bold mb-1">POD</div>
                                            <DynamicPortSelect
                                                placeholder="Port desc."
                                                value={element.shippingTo ? element.shippingTo : ''}
                                                onChange={(newValue) => handleShippingChange(newValue!, rowIndex, 'shippingTo')}
                                                creatable={offer.type === 'Export'}
                                            />
                                        </div>
                                    </td>
                                    {element.prices.map((price, colIndex) => (
                                        <td key={colIndex} className="border-gray-300 p-2 md:border-s table-cell">
                                            <Input
                                                type="number"
                                                id={`price-${colIndex}-${rowIndex}`}
                                                name={`price-${colIndex}`} 
                                                label={`Preț ${offer.containers[colIndex] ? ' - ' : ''}${offer.containers[colIndex] || ''}`}
                                                step="0.01"
                                                value={price}
                                                onChange={(e) => handleCurrentInputChange(e, rowIndex, colIndex)}
                                            />
                                        </td>
                                    ))}
                                    <td className="align-middle justify-center items-center flex md:gap-0 gap-3">
                                        <Tooltip content={`Șterge rută`}>
                                            <button type="button" onClick={() => {deleteRow(rowIndex)}} className="text-red-500 hover:text-red-700 h-[20px] mt-1 flex items-center mx-1" aria-label={`Șterge tip rută`}>
                                                <MdDeleteOutline className='w-[20px] h-[20px] hover:scale-110 duration-75'/>
                                            </button>
                                        </Tooltip>
                                        <Tooltip content={`Copiază rută`}>
                                            <button type="button" onClick={() => {copyRow(rowIndex)}} className="text-blue-500 hover:text-blue-700 h-[14px] mt-1 flex items-center mx-1" aria-label={`Copiază tip rută`}>
                                                <FaRegCopy className='w-[14px] h-[16px] hover:scale-110 duration-75'/>
                                            </button>
                                        </Tooltip>
                                    </td>
                                </tr>
                            ) : ''
                        ))}
                        <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                            <td className="text-center align-middle flex justify-center items-center h-[50px]">
                                <Button onClick={() => {addRow('navlu')}}>   
                                    <FaPlus size={12}/>
                                </Button>
                            </td>
                            {offer.containers.map((name, colIndex) => (
                                <td key={`navludelete${colIndex}`} className="text-center align-middle hidden md:table-cell">
                                    {
                                        colIndex !== 0 ? 
                                        <>
                                            <Tooltip content={`Mută la stânga`}>
                                                <button type="button" onClick={() => {moveColumnLeft(colIndex)}} className="text-blue-500 hover:text-blue-700 h-[20px] flex items-center mx-1" aria-label={`Mută la stânga`}>
                                                    <FaAngleDoubleLeft className='w-[20px] h-[20px] hover:scale-110 duration-75'/>
                                                </button>
                                            </Tooltip>
                                        </> : <></>
                                    }
                                    <Tooltip content={`Șterge tip container`}>
                                        <button type="button" onClick={() => {deleteColumn(colIndex)}} className="text-red-500 hover:text-red-700 h-[20px] mt-1 flex items-center mx-1" aria-label={`Șterge tip container`}>
                                            <MdDeleteOutline className='w-[20px] h-[20px] hover:scale-110 duration-75'/>
                                        </button>
                                    </Tooltip>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
export default NavluSection;