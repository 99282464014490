import React, { useState } from 'react';
import Input from "../../../components/Input";
import CustomSelect from "../../../components/CustomSelect";
import { Button, Tooltip } from 'flowbite-react';
import { handleInputChange, handleSelectChange } from "../../../functions/selectHelper";
import { defaultBookingBill } from "../../../interfaces/Booking";
import { RecommendationResp } from "../../../interfaces/Recommandation";
import { handleOptions } from '../../../functions/generalHelper';
import { errorMessage } from '../../../functions/generalHelper';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { getDateFormat } from '../../../functions/dateHelper';
import TextArea from '../../../components/Textarea';
import BillsTable from './components/BillsTable';
import { FaFileImport } from "react-icons/fa6";
import { BookingUpdateForm } from '../../../validations/booking/CreateBooking';
import { UpdateBookingBillBooking } from '../../../validations/booking/CreateBookingBillBooking';

interface TabProps {
  booking: Partial<BookingUpdateForm>;
  setBooking: React.Dispatch<React.SetStateAction<Partial<BookingUpdateForm>>>;
  recommendations: RecommendationResp[];
}

const BillsTab: React.FC<TabProps> = ({ booking, setBooking, recommendations }) => {
  const [newBill, setNewBill] = useState<UpdateBookingBillBooking>(defaultBookingBill as UpdateBookingBillBooking);
  const [isEdit, setIsEdit] = useState<number>(-1);
  const terminalOptions = handleOptions(recommendations, 'bill-terminal');
  const releaseTypeOptions = handleOptions(recommendations, 'bill-releaseType');
  
  const handleAddOrEditBill = () => {
    if (!newBill.code) {
      errorMessage('Numărul de B/L este obligatoriu!');
      return;
    }
    
    if (isEdit !== -1) {
      const updatedBill = [...booking.bookingBills!];
      updatedBill[isEdit] = newBill;
      setBooking({ ...booking, bookingBills: updatedBill });
      setIsEdit(-1);
    } else {
      setBooking({ ...booking, bookingBills: [...booking.bookingBills!, newBill] });
    }
    setNewBill(defaultBookingBill as UpdateBookingBillBooking);
  };

  const getBillDataFromBooking = () => {
    setNewBill(prev => ({
      ...prev,
      shipperName: booking.shipperName!.trim() !== '' ? booking.shipperName : prev.shipperName,
      shipperAddress: booking.shipperAddress!.trim() !== '' ? booking.shipperAddress : prev.shipperAddress,
      consigneeName: booking.consigneeName!.trim() !== '' ? booking.consigneeName : prev.consigneeName,
      consigneeAddress: booking.consigneeAddress!.trim() !== '' ? booking.consigneeAddress : prev.consigneeAddress,
      commodity: booking.commodity!.trim() !== '' ? booking.commodity : prev.commodity,
      hsCode: booking.hsCode!.trim() !== '' ? booking.hsCode : prev.hsCode,
      loadingPlace: booking.loadingPlace!.trim() !== '' ? booking.loadingPlace : prev.loadingPlace,
    }));
  }

  return (
    <div>
      <div className="rounded-lg border border-gray-200 bg-white p-5 relative mb-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">B/L's</h4>
        <BillsTable booking={booking} isEdit={isEdit} setBooking={setBooking} setIsEdit={setIsEdit} setNewBill={setNewBill} />
      </div>

      <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">
          {isEdit !== -1 ? 'Editează B/L' : 'Adaugă B/L'}
        </h4>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <Input 
                id="code"
                label="Număr B/L"
                value={newBill.code}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newBill, setNewBill, 'code', e.target.value, defaultBookingBill as UpdateBookingBillBooking)}
            />
            <CustomDatePicker
                selected={newBill.date ? new Date(newBill.date) : null}
                onChange={(date) => setNewBill({ ...newBill, date: date })}
                dateFormat={getDateFormat()}
                locale="ro"
                isClearable
                placeholderText="Data B/L"
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <CustomSelect
                value={terminalOptions.find(terminal => terminal.value === newBill.terminal)}
                onChange={(data) => handleSelectChange(newBill, setNewBill, 'terminal', data)}
                options={terminalOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Terminal"
            />
            <CustomSelect
                value={releaseTypeOptions.find(releaseType => releaseType.value === newBill.releaseType)}
                onChange={(data) => handleSelectChange(newBill, setNewBill, 'releaseType', data)}
                options={releaseTypeOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Tip eliberare"
            />
            <Input 
                id="billShipperName"
                label="Nume shipper"
                value={newBill.shipperName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newBill, setNewBill, 'shipperName', e.target.value, defaultBookingBill as UpdateBookingBillBooking)}
            />
            <TextArea 
                id={`billShipperAddress`}
                label="Adresă shipper"
                value={newBill.shipperAddress}
                rows={1}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(newBill, setNewBill, 'shipperAddress', e.target.value)}
            />
            <Input 
                id="billConsigneeName"
                label="Nume consignee"
                value={newBill.consigneeName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newBill, setNewBill, 'consigneeName', e.target.value, defaultBookingBill as UpdateBookingBillBooking)}
            />
            <TextArea 
                id={`billConsigneeAddress`}
                label="Adresă consignee"
                value={newBill.consigneeAddress}
                rows={1}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(newBill, setNewBill, 'consigneeAddress', e.target.value)}
            />
            <TextArea
                id={`billCommodity`}
                label="Marfă"
                value={newBill.commodity}
                rows={1}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(newBill, setNewBill, 'commodity', e.target.value)}
            />
            <Input 
                id="billHsCode"
                label="HS Code"
                value={newBill.hsCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newBill, setNewBill, 'hsCode', e.target.value, defaultBookingBill as UpdateBookingBillBooking)}
            />
            <TextArea
                id={`billLoadingPlace`}
                label="Loc de încărcare"
                value={newBill.loadingPlace}
                rows={1}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(newBill, setNewBill, 'loadingPlace', e.target.value)}
            />
            <div className="flex items-center">
              <Tooltip content="Preia date din booking">
                <button type="button" className="h-4 text-blue-600 hover:text-blue-500" onClick={getBillDataFromBooking}>
                  <FaFileImport />
                </button>
              </Tooltip>
            </div>
        </div>
        <div className="flex justify-end space-x-4 mt-3">
          <Button size="sm" color="gray" onClick={() => {
            setIsEdit(-1);
            setNewBill(defaultBookingBill as UpdateBookingBillBooking);
          }}>
            {isEdit !== -1 ? 'Renunță la editare' : 'Resetează câmpuri'}
          </Button>
          <Button size="sm" onClick={handleAddOrEditBill}>
            {isEdit !== -1 ? 'Editează B/L' : 'Adaugă B/L'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BillsTab;