import { ValidationError } from "class-validator";

export function getAllErrorMessages(errors: ValidationError[]): string[] {
    return errors.reduce((messages: string[], error: ValidationError) => {
        // Adăugăm erorile directe ale proprietății curente
        if (error.constraints) {
            messages.push(...Object.values(error.constraints));
        }
        
        // Pentru erori nested, doar le adăugăm fără context suplimentar
        if (error.children && error.children.length > 0) {
            messages.push(...getAllErrorMessages(error.children));
        }
        
        return messages;
    }, []);
}