import axios from "axios";
import client from '../apollo-client';
import { BookingFile } from "../interfaces/Booking";
import { gql } from "@apollo/client";

const API_URL = `${process.env.REACT_APP_BACKEND_URI}/file`;

export class FileService { 
    async uploadFile(file: File): Promise<{status: string, originalname: string, filename: string}> {
        try {
            const formData = new FormData();
            formData.append('file', file);
            
            const response = await axios.post(`${API_URL}/uploadFile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return {
                status: 'success',
                originalname: response.data.originalname,
                filename: response.data.filename
            };
        } catch (error) {
            console.error('Eroare la încărcarea fișierului:', error);
            return {
                status: 'error',
                originalname: '',
                filename: ''
            };
        }
    }

    async uploadBookingFile(file: File, bookingId: number): Promise<{
        status: string;
        originalName: string;
        storedName: string;
        filePath: string;
      }> {
        try {
          const formData = new FormData();
          formData.append('file', file);
          
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URI}/booking-file/upload/${bookingId}`, 
            formData, 
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            }
          );
      
          return {
            status: 'success',
            originalName: response.data.originalName,
            storedName: response.data.storedName,
            filePath: response.data.filePath
          };
        } catch (error) {
          console.error('Eroare la încărcarea fișierului:', error);
          return {
            status: 'error',
            originalName: '',
            storedName: '',
            filePath: ''
          };
        }
    }

    async getBookingFiles(bookingId: number): Promise<Partial<BookingFile>[]>{
        const response = await client.query({
            query: gql`
                query {
                    bookingFilesByBookingId(bookingId: ${bookingId}) {
                        id
                        originalName
                        bookingId
                        storedName
                        filePath
                        mimeType
                        fileSize
                        uploadDate
                        description
                        user {
                            id
                            name
                        }
                    }
                }
            `,
        });
        return response.data.bookingFilesByBookingId;
    }

    async removeBookingFile(id: number): Promise<{status: string; message: string;}> {
        const response = await client.mutate({
          mutation: gql`
            mutation {
              deleteBookingFile(id: ${id}){
                status
                message
              }
            }
          `,
        });
        return response.data.deleteBookingFile;
    }

}