import React from 'react';
import { Client, Offer, OfferDetail } from '../interfaces/Offer';
import { OfferService } from '../services/OfferService';
import { errorMessage } from './generalHelper';
import { OptionService } from '../services/OptionService';

export const getClientsAndSetInitialIfExists = async (
    offerService: OfferService, 
    isTemplate: boolean, 
    setOffer: React.Dispatch<React.SetStateAction<Offer>>,
    setClients: React.Dispatch<React.SetStateAction<Client[]>>,
    clientId?: number | null,
) => {
    if(!isTemplate){
        await offerService.getClients().then(details => {
            setClients(details);
            if(clientId){
                const currentClient = details.find((client) => client.id === clientId);
                if(currentClient){
                    setOffer((prevData) => ({
                        ...prevData, 
                        clientId: currentClient.id,
                        email: currentClient.email ? currentClient.email : '',
                        tradeId: currentClient.tradeId ? currentClient.tradeId : '',
                        client: currentClient,
                    }));
                }
            }
        }).catch(error => {
            errorMessage(error);
        });
    }
};

export const loadAdditionalServices = async (
    optionService: OptionService, 
    setOffer: React.Dispatch<React.SetStateAction<Offer>>,
    id?: string | null | undefined,
) => {
    try {
        const option = await optionService.getOption('servicii-suplimentare');
        if(!id){
            setOffer(prev => ({ ...prev, additionalServices: option.value || '' }));
        }
    } catch (error) {
        console.log(error);
    }
};

export const getAndSetInitialOfferDetails = async (
    offerService: OfferService, 
    setAllOfferDetails: React.Dispatch<React.SetStateAction<OfferDetail[]>>, 
) : Promise<OfferDetail[] | null> => {
    return offerService.offerDetails().then(details => {
        setAllOfferDetails(details);
        return details;
    })
    .catch(error => {
        errorMessage(error);
        return null;
    });
};

export const setOfferDataIfExists = async (
    offerService: OfferService, 
    isTemplate: boolean, 
    setOffer: React.Dispatch<React.SetStateAction<Offer>>,
    id?: string | null | undefined,
) => {
    if(id){
        offerService.getOfferById(parseInt(id)).then((data) => {
            setOffer((prevData) => (
                isTemplate ? {
                    ...data, 
                    clientId: prevData.clientId, 
                    client: prevData.client, 
                    validityStartDate: prevData.validityStartDate, 
                    validityEndDate: prevData.validityEndDate, 
                } : data
            ));
        });
    }
}