import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_RO } from '../../../functions/langHelper';
import { ColDef } from 'ag-grid-community';
import { formatDateTime } from '../../../functions/dateHelper';
import { Badge } from 'flowbite-react';
import { EmailQueue } from '../../../interfaces/Booking';

interface TableProps {
    emails: Partial<EmailQueue>[];
}

const Table: React.FC<TableProps> = ({ emails }) => {
  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const gridOptions = { rowHeight: 35 };
  const columns = useMemo<ColDef<Partial<EmailQueue>>[]>(() => [
    {
      headerName: 'Id',
      field: 'id', 
      sortable: true, 
      filter: 'agNumberColumnFilter', 
      floatingFilter: true, 
      minWidth: 100,
      hide: true
    },
    { 
      headerName: 'De la', 
      field: 'fromName', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      minWidth: 140,
      floatingFilter: true,
    },
    { 
      headerName: 'Tip', 
      field: 'type', 
      sortable: true, 
      filter: 'agSetColumnFilter', 
      minWidth: 90,
      floatingFilter: true,
      cellStyle: { display: 'flex',  alignItems: 'center' },
      filterParams: {
        valueFormatter: (params: any) => {
          const statusMap: { [key: string]: string } = {
            arrivalNotice: 'Preavizare',
            deliveryOrder: 'Liber',
            arrivalUpdate: 'Avizare',
          };
          return statusMap[params.value];
        },
      },
      cellRenderer: (params : any) => <div>{ params.value === 'arrivalNotice' ? <Badge color="success">Preavizare</Badge> : params.value === 'deliveryOrder' ? <Badge color="blue">Liber</Badge> : <Badge color="warning">Avizare</Badge> }</div>
    },
    { 
      headerName: 'Catre', 
      field: 'recipients', 
      autoHeight: true,
      sortable: true, 
      filter: 'agTextColumnFilter', 
      minWidth: 140,
      floatingFilter: true,
      cellRenderer: (params : any) => <div>{ params.value ? params.value.join(', ') : params.data.reference }</div>
    },
    { 
      headerName: 'Consignee', 
      field: 'reference', 
      autoHeight: true,
      sortable: true, 
      filter: 'agTextColumnFilter', 
      minWidth: 140,
      floatingFilter: true,
    },
    { 
      headerName: 'Status', 
      field: 'status', 
      sortable: true, 
      filter: 'agSetColumnFilter',
      minWidth: 90,
      floatingFilter: true,
      cellStyle: { display: 'flex',  alignItems: 'center' },
      filterParams: {
        valueFormatter: (params: any) => {
          const statusMap: { [key: string]: string } = {
            PENDING: 'În așteptare',
            PROCESSING: 'În procesare',
            SENT: 'Trimis',
            FAILED: 'Eșuat',
            CANCELLED: 'Anulat'
          };
          return statusMap[params.value];
        },
      },
      cellRenderer: (params: any) => {
        const statusMap: { [key: string]: string } = {
          PENDING: 'În așteptare',
          PROCESSING: 'În procesare',
          SENT: 'Trimis',
          FAILED: 'Eșuat',
          CANCELLED: 'Anulat'
        };
        const statusColorMap: { [key: string]: string } = {
          PENDING: 'warning',
          PROCESSING: 'info',
          SENT: 'success',
          FAILED: 'failure',
          CANCELLED: 'gray'
        };
        return <Badge color={statusColorMap[params.value]}>{statusMap[params.value]}</Badge>;
      }
    },
    {
      headerName: 'Data',
      field: 'scheduledFor',
      minWidth: 120,
      sort: 'desc',
      sortable: true,
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      filterParams: {
        inRangeInclusive: true,
        defaultOption: 'inRange',
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const cellDate = new Date(cellValue);
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        }
      },
      cellRenderer: (params : any) => params.value ? formatDateTime(params.value) : ''
    },
    {
      headerName: 'Data trimiterii',
      field: 'sentAt',
      minWidth: 120,
      sortable: true,
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      filterParams: {
        inRangeInclusive: true,
        defaultOption: 'inRange',
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const cellDate = new Date(cellValue);
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
            return 0;
        }
      },
      cellRenderer: (params : any) => params.value ? formatDateTime(params.value) : ''
    },
    { 
      headerName: 'Mesaj eroare', 
      field: 'errorMessage',
      sortable: true, 
      filter: 'agTextColumnFilter', 
      minWidth: 160,
      floatingFilter: true,
      cellRenderer: (params : any) => <div style={{ whiteSpace: 'normal', lineHeight: 1.2 }}>{params.value}</div>
    },
  ], []);
  
  return (
    <>
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={emails}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
        gridOptions={gridOptions}
        enableCharts={true}
        sideBar={true}
      />
    </>
  );
};

export default Table;