import { Client } from '../interfaces/Client';
import { formatDateForBackend } from '../functions/dateHelper';
import client from '../apollo-client';
import { gql } from '@apollo/client';
import { ArrivalNotice, BillCheck, Booking, BookingBill, BookingBillTable, CargoManifest, Container, EmailQueue } from '../interfaces/Booking';
import { errorMessage, removeTypename } from '../functions/generalHelper';
import { FileService } from './FileService';
import { plainToClass } from 'class-transformer';
import { BookingCreateForm, BookingUpdateForm, CreateBooking } from '../validations/booking/CreateBooking';
import { validateOrReject } from 'class-validator';
import 'reflect-metadata';
import { getAllErrorMessages } from '../functions/validationHelper';

export class BookingService {

    private async validateBooking(booking: Partial<BookingCreateForm>): Promise<void> {
        const bookingValidation = plainToClass(CreateBooking, booking);
        try {
          await validateOrReject(bookingValidation);
        } catch (errors) {
          if (Array.isArray(errors)) {
              const messages = getAllErrorMessages(errors);
              throw new Error(messages.map(msg => `• ${msg}`).join('\n'));
          }
          throw errors;
        }
    }
  
    async getClients(): Promise<Client[]> {
        const response = await client.query({
            query: gql`
                query {
                    clients {
                        id
                        company
                        email
                        tradeId
                        contacts {
                            email
                        }
                    }
                }
            `,
        });
        return response.data.clients.map((client: Client) => ({
            id: client.id,
            company: client.company,
            tradeId: client.tradeId,
            email: client.email || (client.contacts && client.contacts.length > 0 ? client.contacts[0].email : '')
        }));
    }

    async getClientsWithData(): Promise<Partial<Client>[]> {
        const response = await client.query({
            query: gql`
                query {
                    clients {
                        id
                        company
                        gstNumber
                        country
                        state
                        city
                        street
                        minimalAddress
                        fullAddress
                        postalCode
                        phone
                        email
                        contacts {
                            email
                        }
                    }
                }
            `,
        });
        return response.data.clients.map((client: Client) => ({
            id: client.id,
            company: client.company,
            gstNumber: client.gstNumber,
            country: client.country,
            state: client.state,
            city: client.city,
            street: client.street,
            minimalAddress: client.minimalAddress,
            postalCode: client.postalCode,
            phone: client.phone,
            email: client.email || (client.contacts && client.contacts.length > 0 ? client.contacts[0].email : '')
        }));
    }

    async getBookings(): Promise<Partial<Booking>[]>{
      const response = await client.query({
          query: gql`
              query {
                  bookings {
                      id
                      number
                      status
                      shippingType
                      loadType
                      bookingType
                      shippingLineName
                      forwarderName
                      creationDate
                      teuCount
                      lastVesselCode
                      motherVesselCode
                      tradeId
                      client{
                        id
                        company
                      }
                      pod
                      pol
                      user{
                          id
                          name
                      }
                      bookingBills{
                        id
                        code
                      }
                  }
              }
          `,
      });
      return response.data.bookings;
    }

    async create(booking: Partial<BookingCreateForm>): Promise<{status: string; message: string;}> {
      try {
        await this.validateBooking(booking);
        const response = await client.mutate({
          mutation: gql`
            mutation createBooking($input: CreateBookingDto!){
                createBooking(input: $input){
                  status
                  message
                }
            }
          `,
          variables: {
            input: booking
          }
        });
        return response.data.createBooking;
      } catch (error) {
        if (error instanceof Error) {
          errorMessage(error.message);
        } else {
          errorMessage('A apărut o eroare neașteptată!');
        }
        throw error;
      }
    }

    async updateExport(booking: Partial<BookingUpdateForm>): Promise<{status: string; message: string;}> {
      try {
        if(!booking.shippingType?.includes('Export')){
          return { status: 'error', message: 'Momentan functia de salvare este accesibila numai pentru bookingurile de export!' };
        }
        await this.validateBooking(booking);
        const { routes, bookingBills, containers, ...bookingData } = booking;
        const response = await client.mutate({
          mutation: gql`
            mutation updateExport($input: UpdateBookingDto!){
                updateExport(input: $input){
                  status
                  message
                }
            }
          `,
          variables: {
            input: {
              ...removeTypename(bookingData),
              routes: routes!.map((route) => removeTypename(route)),
              bookingBills: bookingBills!.map((bookingBill) => removeTypename(bookingBill)),
              containers: containers!.map((container) => removeTypename(container)),
            }
          }
        });
        return response.data.updateExport;
      } catch (error) {
        if (error instanceof Error) {
          errorMessage(error.message);
        } else {
          errorMessage('A apărut o eroare neașteptată!');
        }
        throw error;
      }
    }

    async update(booking: Booking): Promise<Booking> {
        const response = await client.mutate({
          mutation: gql`
            mutation updateBooking($input: UpdateBookingDto!){
                updateBooking(input: $input){
                  id
                }
            }
          `,
          variables: {
            input: {
              id: booking.id,
              number: booking.number,
              status: booking.status,
              offerId: booking.offerId,
              clientId: booking.clientId,
              shippingType: booking.shippingType,
              loadType: booking.loadType,
              bookingType: booking.bookingType,
              shippingLineName: booking.shippingLineName,
              svcTermOrigin: booking.svcTermOrigin,
              svcTermDestination: booking.svcTermDestination,
              creationDate: booking.creationDate,
              createdBy: booking.createdBy,
              blockStatus: booking.blockStatus,
              waiting: booking.waiting,
              cancelReason: booking.cancelReason,
              cancelledDate: booking.cancelledDate,
              blockStowage: booking.blockStowage,
              forwarderName: booking.forwarderName,
              forwarderAddress: booking.forwarderAddress,
              forwarderPhone: booking.forwarderPhone,
              forwarderEmail: booking.forwarderEmail,
            }
          }
        });
        return response.data.updateBooking;
    }
    
    async deleteBooking(id: number): Promise<boolean> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            deleteBooking(id: ${id})
          }
        `,
      });
      return response.data.deleteBooking;
    }
    
    async getBookingById(id: number): Promise<Booking> {
        const response = await client.query({
          query: gql`
            query {
              booking(id: ${id}) {
                id
                number
                status
                offerId
                clientId
                shippingType
                loadType
                bookingType
                shippingLineName
                svcTermOrigin
                svcTermDestination
                creationDate
                createdBy
                blockStatus
                waiting
                cancelReason
                cancelledDate
                blockStowage
                forwarderName
                forwarderAddress
                forwarderPhone
                forwarderEmail
                createdAt
                updatedAt
                bookingBills{
                  id
                  code
                  shipperName
                  shipperAddress
                  shipperPhone
                  shipperEmail
                  consigneeName
                  consigneeAddress
                  consigneePhone
                  consigneeEmail
                  notifyName
                  notifyAddress
                  notifyPhone
                  notifyEmail
                  date
                  terminal
                  commodity
                  translatedCommodity
                  shortCommodity
                  cargos {
                    id
                    bookingContainerId
                    bookingBillId
                    hsCode
                    packageNumber
                    packageType
                    weight
                    volume
                  }
                }
                routes {
                  id
                  from
                  to
                  vesselCode
                  vesselName
                  etd
                  atd
                  eta
                  ata
                }
                movements{
                  id
                  movement
                  port
                  containerCode
                  vesselCode
                  vesselName
                  date
                  action
                }
                bookingContainers {
                  id
                  containerNumber
                  sealNumber
                  bookingId
                  tare
                  weight
                  volume
                  type
                  size
                  iso
                  bookingBillId
                  freeDays
                  dangerCargo
                  packageNumber
                }
                user {
                  id
                  name
                  email
                }
              }
            }
          `,
        });
    
        const bookingData = response.data.booking;

        const { cargoManifests, bookingBills } = bookingData.bookingBills.reduce((acc : any, bill : any) => {
          const { cargos, ...billData } = bill;
          acc.cargoManifests.push(...cargos);
          acc.bookingBills.push(billData);
          return acc;
        }, { cargoManifests: [], bookingBills: [] });

        // Construct the final Booking object
        const booking: Booking = {
          ...bookingData,
          // bls: bookingData.bookingBills.map((bl : any) => bl.code),
          creationDate: bookingData.creationDate ? new Date(bookingData.creationDate) : null,
          containers: bookingData.bookingContainers,
          bookingBills,
          cargoManifests,
        };
        return booking;
    }
    
    async getBookingForEdit(id: number) : Promise<BookingUpdateForm>{
      const response = await client.query({
        query: gql`
          query {
            findOneForEdit(id: ${id}) {
              id
              number
              status
              shippingType
              loadType
              bookingType
              shippingLineName
              creationDate
              cancelReason
              cancelledDate
              blockStatus
              waiting
              loadingPlace
              pol
              pod
              hsCode
              commodity
              note
              svcTermOrigin
              svcTermDestination
              shipperName
              shipperAddress
              consigneeName
              consigneeAddress
              clientId
              offerId
              createdBy
              routes {
                id
                from
                to
                vesselCode
                vesselName
                etd
                atd
                eta
                ata
              }
              
              bookingBills {
                id
                code
                date
                terminal
                releaseType
                shipperName
                shipperAddress
                consigneeName
                consigneeAddress
                hsCode
                loadingPlace
                commodity
              }
              
              containers {
                id
                containerNumber
                size
                type
                bls
                iso
                freeDays
                tare
                weight
                vgmNumber
                volume
                grossWeight
                sealNumber
                packageNumber
                mrnNumber
                riNumber
                customsNumber
                arrivalCarNumber
                departureCarNumber
                reeferTemp
                imoDetails
                oogDims
                category
                cargoType
                remarks
                dangerCargo
                soc
              }
            }
          }
        `,
      });
      return response.data.findOneForEdit;
    }

    async createBookingContainer(container: Container): Promise<Container> {
        const response = await client.mutate({
          mutation: gql`
            mutation {
                createBookingContainer(
                  input: {
                      id: ${container.id !== 0 ? container.id : null},
                      bookingId: ${container.bookingId},
                      bookingBillId: ${container.bookingBillId ? `${container.bookingBillId}` : null},
                      containerNumber: "${container.containerNumber}",
                      sealNumber: ${container.sealNumber ? `"${container.sealNumber}"` : null},
                      tare: ${container.tare ? `${container.tare}` : null},
                      weight: ${container.weight ? `${container.weight}` : null},
                      volume: ${container.volume ? `${container.volume}` : null},
                      iso: ${container.iso ? `"${container.iso}"` : null},
                      type: ${container.type ? `"${container.type}"` : null},
                      size: ${container.size ? `"${container.size}"` : null},
                      packageNumber: ${container.packageNumber ? `${container.packageNumber}` : null},
                      dangerCargo: ${container.dangerCargo ? true : false},
                      freeDays: ${container.freeDays ? `${container.freeDays}` : null}
                    }
                ){
                  id
                  containerNumber
                  sealNumber
                  bookingId
                  tare
                  weight
                  volume
                  type
                  size
                  iso
                  bookingBillId
                  freeDays
                  dangerCargo
                  packageNumber
                }
            }
          `,
        });
        return response.data.createBookingContainer;
    }

    async createBookingCargo(cargo: CargoManifest): Promise<CargoManifest> {
        const response = await client.mutate({
          mutation: gql`
            mutation createBookingCargo($input: CreateCargoDto!) {
                createBookingCargo(input: $input){
                  id
                  bookingContainerId
                  bookingBillId
                  hsCode
                  packageNumber
                  packageType
                  weight
                  volume
                }
            }
          `,
          variables: {
            input: {
              ...removeTypename(cargo),
              id: cargo.id !== 0 ? cargo.id : null,
            }
          }
        });
        return response.data.createBookingCargo;
    }

    async changeBookingStatus(bookingId: number, newStatus: string): Promise<void> {
        const response = await client.mutate({
          mutation: gql`
            mutation {
                changeBookingStatus(
                  bookingId: ${bookingId},
                  newStatus: "${newStatus}",
                ){
                  status
                }
            }
          `,
        });
      return response.data.changeBookingStatus;
    }

    async sendArrivalNotice(arrivalNotice : ArrivalNotice): Promise<{errors: string[]; emails: {id: number; email: string|null; name: string|null; booking: string|null;}[];}> {
      const response = await client.mutate({
        mutation: gql`
          mutation sendArrivalNotice($input: CreateArrivalNoticeDto!){
              sendArrivalNotice(input: $input){
                errors
                emails{
                  id
                  email
                  name
                  booking
                }
              }
          }
        `,
        variables: {
          input: arrivalNotice
        }
      });
    return response.data.sendArrivalNotice;
    }

    async sendArrivalNoticeEmail(formData : { id: number; email: string | null; name: string | null; booking: string | null; }[]) : Promise<{status: string; message: string;}>{
      const cleanData = formData.map(({ id, email, name, booking }) => ({
        id,
        email,
        name,
        booking
      }));
    
      const response = await client.mutate({
        mutation: gql`
          mutation sendArrivalNoticeEmail($input: [SendArrivalNoticeDto!]!){
              sendArrivalNoticeEmail(input: $input){
                status
                message
              }
          }
        `,
        variables: {
          input: cleanData
        }
      });
      return response.data.sendArrivalNoticeEmail;
    }

    async getBookingBillById(id : number) : Promise<BookingBill> {
      const response = await client.query({
        query: gql`
          query {
            bookingBill(id: ${id}) {
              id
              code
              shipperName
              shipperAddress
              shipperPhone
              shipperEmail
              consigneeName
              consigneeAddress
              consigneePhone
              consigneeEmail
              notifyName
              notifyAddress
              notifyPhone
              notifyEmail
              date
              terminal
              commodity
              translatedCommodity
              shortCommodity
              bookingContainers {
                containerNumber
                size
                type
                sealNumber
                weight
                volume
                freeDays
                dangerCargo
              }
            }
          }
        `,
      });
      return {
        ...response.data.bookingBill, 
        date: response.data.bookingBill.date ? new Date(response.data.bookingBill.date) : null,
        containers: response.data.bookingBill.bookingContainers
      };
    }

    async createOrUpdateBookingBill(bill : BookingBill) : Promise<BookingBill>{
      const response = await client.mutate({
        mutation: gql`
          mutation createBookingBill($input: CreateBookingBillDto!){
              createBookingBill(input: $input){
                id
                code
                shipperName
                shipperAddress
                shipperPhone
                shipperEmail
                consigneeName
                consigneeAddress
                consigneePhone
                consigneeEmail
                notifyName
                notifyAddress
                notifyPhone
                notifyEmail
                date
                terminal
                commodity
                translatedCommodity
                shortCommodity
              }
          }
        `,
        variables: {
          input: {
            id: bill.id !== 0 ? bill.id : null,
            bookingId: bill.bookingId,
            date: bill.date ? formatDateForBackend(bill.date) : null,
            code: bill.code ? bill.code : null,
            shipperName: bill.shipperName ? bill.shipperName : null,
            shipperAddress: bill.shipperAddress ? bill.shipperAddress : null,
            shipperPhone: bill.shipperPhone ? bill.shipperPhone : null,
            shipperEmail: bill.shipperEmail ? bill.shipperEmail : null,
            consigneeName: bill.consigneeName ? bill.consigneeName : null,
            consigneeAddress: bill.consigneeAddress ? bill.consigneeAddress : null,
            consigneePhone: bill.consigneePhone ? bill.consigneePhone : null,
            consigneeEmail: bill.consigneeEmail ? bill.consigneeEmail : null,
            notifyName: bill.notifyName ? bill.notifyName : null,
            notifyAddress: bill.notifyAddress ? bill.notifyAddress : null,
            notifyPhone: bill.notifyPhone ? bill.notifyPhone : null,
            notifyEmail: bill.notifyEmail ? bill.notifyEmail : null,
            terminal: bill.terminal ? bill.terminal : null,
            commodity: bill.commodity ? bill.commodity : null,
            translatedCommodity: bill.translatedCommodity ? bill.translatedCommodity : null,
            shortCommodity: bill.shortCommodity ? bill.shortCommodity : null,
          }
        },
      });
      return response.data.createBookingBill;
    }

    async removeBookingBill(id: number): Promise<boolean> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            removeBookingBill(id: ${id})
          }
        `,
      });
      return response.data.removeBookingBill;
    }

    async removeBookingContainer(id: number): Promise<boolean> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            removeBookingContainer(id: ${id})
          }
        `,
      });
      return response.data.removeBookingContainer;
    }
  
    async removeCargo(id: number): Promise<boolean> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            removeCargo(id: ${id})
          }
        `,
      });
      return response.data.removeCargo;
    }

    async getBills(): Promise<Partial<BookingBillTable>[]>{
      const response = await client.query({
          query: gql`
              query {
                bookingBills {
                  id
                  code
                  consigneeName
                  shipperName
                  notifyName
                  terminal
                  date
                  bookingContainers {
                    id
                    containerNumber
                  }
                  booking {
                    id
                    number
                    lastVesselCode
                    motherVesselCode
                    createdBy
                    user {
                      id
                      name
                    }
                  }
                }
              }
          `,
      });
      return response.data.bookingBills;
    } 

    async getBillChecks(): Promise<Partial<BillCheck>[]>{
      const response = await client.query({
          query: gql`
              query {
                billChecks {
                  id
                  billNumber
                  vesselName
                  vesselCode
                  initialVesselCode
                  status
                  info
                }
              }
          `,
      });
      return response.data.billChecks;
    } 

    async deleteBillCheck(id: number): Promise<boolean> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            removeBillCheck(id: ${id})
          }
        `,
      });
      return response.data.removeBillCheck;
    }

    async updateBillCheck(billCheck: Partial<BillCheck>): Promise<BillCheck> {
      const response = await client.mutate({
        mutation: gql`
          mutation updateBillCheck($input: UpdateBillCheckDto!){
              updateBillCheck(input: $input){
                id
              }
          }
        `,
        variables: {
          input: {
            id: billCheck.id,
            status: billCheck.status,
          }
        }
      });
      return response.data.updateBillCheck;
    }

    async createBillCheck(billCheck: Partial<BillCheck>): Promise<{status:string;message:string;}> {
      const response = await client.mutate({
        mutation: gql`
          mutation createBillCheck($input: CreateBillCheckDto!){
              createBillCheck(input: $input){
                status
                message
              }
          }
        `,
        variables: {
          input: {
            billNumber: billCheck.billNumber,
          }
        }
      });
      return response.data.createBillCheck;
    }

    async importBookings(file: File, fileType: string): Promise<{status: string; message: string;}> {
      const fileService = new FileService();
      const fileResponse = await fileService.uploadFile(file);
      if(fileResponse.status === 'success'){
          try {
              const response = await client.mutate({
                  mutation: gql`
                      mutation {
                          importBookings(filename: "${fileResponse.filename}", type: "${fileType}"){
                              status
                              message
                          }
                      }
                  `,        
              });
              return response.data.importBookings;
          } catch (error) {
              return { status: 'error', message: 'Ceva nu a functionat va rugam incercati din nou.' };
          }
      }else{
          return { status: 'error', message: 'Din pacate am intampinat o eroare la incarcarea fisierului.' };
      }
    }
    
    async getEmailQueue(): Promise<Partial<EmailQueue>[]>{
      const response = await client.query({
          query: gql`
              query {
                  getEmailQueue {
                    id
                    subject
                    body
                    recipients
                    cc
                    bcc
                    replyTo
                    from
                    fromName
                    priority
                    status
                    scheduledFor
                    retryCount
                    lastRetryAt
                    errorMessage
                    createdAt
                    updatedAt
                    sentAt
                    isActive
                    maxRetries
                    reference
                    type
                  }
              }
          `,
      });
      return response.data.getEmailQueue;
    }
}