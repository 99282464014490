import React, { useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueSetterParams } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { defaultRoute } from '../../../../interfaces/Booking';
import { CellRenderer } from '../../../../functions/agGridHelper';
import Actions from '../../../../components/Actions';
import { AG_GRID_LOCALE_RO } from '../../../../functions/langHelper';
import { formatDate } from '../../../../functions/dateHelper';
import { successMessage } from '../../../../functions/generalHelper';
import { BookingUpdateForm } from '../../../../validations/booking/CreateBooking';
import { UpdateRoute } from '../../../../validations/booking/CreateRoute';

interface RoutesTableProps {
  booking: Partial<BookingUpdateForm>;
  setBooking: React.Dispatch<React.SetStateAction<Partial<BookingUpdateForm>>>;
  isEdit: number;
  setIsEdit: React.Dispatch<React.SetStateAction<number>>;
  setNewRoute: React.Dispatch<React.SetStateAction<UpdateRoute>>;
}

const RoutesTable: React.FC<RoutesTableProps> = ({ booking, setBooking, isEdit, setIsEdit, setNewRoute }) => {
    const handleEditRoute = (index : number) => {
      setIsEdit(index);
      setNewRoute(booking.routes![index]);
    }

    const handleDeleteRoute = useCallback((index : number) => {
      if(index === isEdit) {
          setIsEdit(10000);
          setNewRoute(defaultRoute as UpdateRoute);
      }
      setBooking((prevBooking) => ({ ...prevBooking, routes: prevBooking.routes!.filter((routes : UpdateRoute, curentIndex : number) => { return curentIndex !== index}) })); 
    },[booking.routes, isEdit, setBooking, setNewRoute, setIsEdit]);

    const modificaCelula = async (params : any, coloana : string) => {
      // @ts-ignore
      params.data[coloana] = params.newValue ? params.newValue : '';
      
      setBooking(prev => ({
        ...prev,
        routes: prev.routes!.map((route, index) =>
          index === params.node.rowIndex
            ? { ...route, [coloana]: params.newValue ? params.newValue : '' }
            : route
        )
      }));
      successMessage('Ruta editata cu succes!');
    }
    
    const columns: ColDef<UpdateRoute>[] = useMemo(() => [
      { 
        headerName: 'Cod Voiaj', 
        field: 'vesselCode', 
        editable: true,
        cellClass: 'group',
        minWidth: 130,
        cellRenderer: CellRenderer, 
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'vesselCode');
          return true; 
        },
      },
      { 
        headerName: 'Nume navă', 
        field: 'vesselName', 
        editable: true,
        cellClass: 'group',
        minWidth: 130,
        cellRenderer: CellRenderer, 
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'vesselName');
          return true; 
        },
      },
      { 
        headerName: 'POL', 
        field: 'from',
        minWidth: 130, 
      },
      { 
        headerName: 'POD', 
        field: 'to', 
        minWidth: 130, 
      },
      { 
        headerName: 'ETD', 
        field: 'etd', 
        minWidth: 130,
        cellRenderer: (params : any) => {
          return params.value ? formatDate(params.value) : '';
        }
      },
      { 
        headerName: 'ATD', 
        field: 'atd', 
        minWidth: 130,
        cellRenderer: (params : any) => {
          return params.value ? formatDate(params.value) : '';
        }
      },
      { 
        headerName: 'ETA', 
        field: 'eta', 
        minWidth: 130,
        cellRenderer: (params : any) => {
          return params.value ? formatDate(params.value) : '';
        }
      },
      { 
        headerName: 'ATA', 
        field: 'ata', 
        minWidth: 130,
        cellRenderer: (params : any) => {
          return params.value ? formatDate(params.value) : '';
        }
      },
      {
        headerName: 'Acțiuni',
        minWidth: 130,
        cellRenderer: (params: any) => (
          <>
            <Actions
              id={params.node.rowIndex}
              onEdit={() => handleEditRoute(params.node.rowIndex)}
              onDelete={() => handleDeleteRoute(params.node.rowIndex)}
              moduleName="Rută"
            />
          </>
        )
      }
    ], [booking.routes, handleDeleteRoute, handleEditRoute, modificaCelula]);

    return (
        <div className="ag-theme-quartz">
            <AgGridReact 
              localeText={AG_GRID_LOCALE_RO}
              columnDefs={columns} 
              rowData={booking.routes}
              defaultColDef={{ flex: 1, minWidth: 2 }}
              domLayout='autoHeight'
              pagination={false}
              gridOptions={{rowHeight: 35}}
              suppressClickEdit={true}
              stopEditingWhenCellsLoseFocus={true}
            />
        </div>
    );
};

export default RoutesTable;