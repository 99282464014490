import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'flowbite-react';
import { Client, Offer, OfferDetail, importCategories } from '../../interfaces/Offer';
import { OfferService } from '../../services/OfferService';
import { OptionService } from '../../services/OptionService';
import { NavLink, useParams } from 'react-router-dom';
import GeneralDataSection from './components/GeneralDataSection';
import NavluSection from './components/NavluSection';
import LocaleSection from './components/LocaleSection';
import TransportTerestru from './components/TransportTerestru';
import { getAndSetInitialOfferDetails, getClientsAndSetInitialIfExists, loadAdditionalServices, setOfferDataIfExists } from '../../functions/offerFormHelper';
import SuprataxeSection from './components/SuprataxeSection';
import DetaliiExportSection from './components/DetaliiExportSection';
import { CurrencyService } from '../../services/CurrencyService';
import { Currency } from '../../interfaces/Currency';
import { RecommandationService } from '../../services/RecommandationService';
import { RecommendationResp } from '../../interfaces/Recommandation';
import Loader from '../../components/CustomLoader';
import RichTextareaSection from '../../components/RichTextareaSection';

interface FormInterface {
    isTemplate: boolean;
    onSave: (id: string | undefined, isTemplate: boolean, status : string) => void;
    offer: Offer;
    setOffer: React.Dispatch<React.SetStateAction<Offer>>;
    clientId? : number | null;
}

const Form: React.FC<FormInterface> = ({isTemplate, onSave, offer, setOffer, clientId}) => {
    const { id } = useParams<{ id?: string }>();
    const [clients, setClients] = useState<Client[]>([]);
    const [allOfferDetails, setAllOfferDetails] = useState<OfferDetail[]>([]);
    const offerService = new OfferService();
    const optionService = new OptionService();
    const effectRan = useRef(false);
    const [recommendations, setRecommendations] = useState<RecommendationResp[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const currencyService = new CurrencyService();
    const recommendationsService = new RecommandationService();
    const [loading, setLoading] = useState<boolean>(false);

    // ia recomandarile(pentru containere) si valutele
    useEffect(() => {
        recommendationsService.getRecommendations().then(data => setRecommendations(data));
        currencyService.getCurrencies().then((data) => setCurrencies(data));
    }, []);

    // seteaza datele generale ale ofertei
    useEffect(() => {
        setLoading(true);
        if (effectRan.current === false) {
            const runEffects = async () => {
                getAndSetInitialOfferDetails(offerService, setAllOfferDetails).then(details => {
                    if(!id && details){
                        setAdditionalServicesToOffer(details);
                    }   
                });
                await loadAdditionalServices(optionService, setOffer, id);
                await setOfferDataIfExists(offerService, isTemplate, setOffer, id);
                await getClientsAndSetInitialIfExists(offerService, isTemplate, setOffer, setClients, clientId);
                setLoading(false);
            };
    
          runEffects();
          return () => {
            effectRan.current = true;
          };
        }
    }, []);

    const setAdditionalServicesToOffer = useCallback(
        (details : (OfferDetail[] | null) = null) => {
            if(offer.type==="Import"){
                setOffer(prev => ({ ...prev, offerDetails: (details ? details : allOfferDetails).filter((detail) => importCategories.includes(detail.category)) }));
            }else{
                setOffer(prev => ({ ...prev, offerDetails: (details ? details : allOfferDetails).filter((detail) => !importCategories.includes(detail.category)) }));
            }
        }
    , [offer.type]);

    useEffect(setAdditionalServicesToOffer, [offer.type]);
    
    const handleSubmit = (type: string) => {
        const status = type==="send" ? "Trimisă" : (!id ? "Creată" : "Modificată");
        onSave(id, isTemplate, status);
    };

    const memoizedGeneralDataSection = useMemo(() => (
        <GeneralDataSection 
            offer={offer} 
            setOffer={setOffer} 
            isTemplate={isTemplate} 
            clients={clients} 
            currencies={currencies}
        />
    ), [offer, isTemplate, clients]);
 
    return (
        <div className="px-4 relative">
            {memoizedGeneralDataSection}
            <NavluSection offer={offer} setOffer={setOffer} recommendations={recommendations} />
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <LocaleSection offer={offer} setOffer={setOffer} />
                <TransportTerestru offer={offer} setOffer={setOffer} />
            </div>
            {
                offer.type === 'Import' ? 
                    <>
                        <SuprataxeSection offer={offer} setOffer={setOffer} allOfferDetails={allOfferDetails}/>
                        <RichTextareaSection title="Servicii suplimentare" initialValue={offer.additionalServices} onChange={(content : string) => {setOffer((prev) => ({...prev, additionalServices: content}))}} />
                        {/* <ServiciiSuplimentareSection editorFocus={editorFocus} setEditorFocus={setEditorFocus} editorKey={editorKey} editorContent={editorContent} setEditorContent={setEditorContent} /> */}
                    </>
                :
                    <>
                        <DetaliiExportSection offer={offer} setOffer={setOffer} allOfferDetails={allOfferDetails}/>
                    </>
            }
            { offer.beginningText !== null ? 
                <RichTextareaSection title="Formulă de adresare" initialValue={offer.beginningText} onChange={(content : string) => {setOffer((prev) => ({...prev, beginningText: content}))}} />
                : <></>
            }
            <div className="flex justify-end space-x-4 mt-3 mb-5">
                <NavLink to="/crm/offers/index">
                    <Button size="sm" color="gray">Înapoi</Button>
                </NavLink>
                <Button size="sm" type="button" onClick={() => {handleSubmit('save')}}>Salvează</Button>
                {
                    !isTemplate ? <Button size="sm" type="button" onClick={() => {handleSubmit('send')}}>Salvează și trimite</Button> : <></>
                }
            </div>
            <div className={loading ? `h-full fixed left-0 top-0 bottom-0 right-0 z-[100]` : ``}>
                <Loader isLoading={loading} />
            </div>
        </div>
    );
};

export default React.memo(Form);
