import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'flowbite-react';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import Input from '../../../components/Input';
import { BookingBill, defaultBookingBill } from '../../../interfaces/Booking';
import { handleInputChange } from '../../../functions/selectHelper';
import { BookingService } from '../../../services/BookingService';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { getDateFormat } from '../../../functions/dateHelper';
import TextArea from '../../../components/Textarea';

interface BillModalProps {
    id: number;
    bookingId: number;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: (bill : BookingBill, create : boolean) => void;
}

const BillModal: React.FC<BillModalProps> = ({ id, bookingId, showModal, setShowModal, onSave }) => {
    const [bill, setBill] = useState<BookingBill>(defaultBookingBill as BookingBill);
    const bookingService = new BookingService();

    useEffect(() => {
        if (!showModal) {
            setBill(defaultBookingBill as BookingBill);
        }else{
            if(id !== 0){
                bookingService.getBookingBillById(id).then((data) => {setBill(data);});
            }
        }
    }, [showModal, id]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const newBill = await bookingService.createOrUpdateBookingBill({...bill, bookingId: bookingId});
            onSave(newBill, bill.id ? false : true);
            setShowModal(false);
            successMessage('B/L salvat cu succes!');
        } catch (error) {
            errorMessage('A aparut o eroare!');
        }
    };

    return (
        <Modal show={showModal} size="5xl" popup onClose={() => setShowModal(false)}>
            <Modal.Header>Adaugă B/L</Modal.Header>
            <Modal.Body className="overflow-auto">
                <div className="space-y-2 pt-5">
                    <form onSubmit={handleSubmit}>
                        <div className="gap-4 grid grid-cols-3 mb-4">
                            <Input 
                                id={`code`}
                                label="Număr"
                                value={bill.code}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'code', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <Input 
                                id={`terminal`}
                                label="Terminal"
                                value={bill.terminal}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'terminal', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <div>
                                <CustomDatePicker
                                    selected={bill.date ? new Date(bill.date) : null}
                                    onChange={(date) => setBill(prev => ({ ...prev, date: date }))}
                                    dateFormat={getDateFormat()}
                                    locale="ro"
                                    isClearable
                                    placeholderText="Data"
                                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div className="gap-4 grid grid-cols-4 mb-2">
                            <Input 
                                id={`shipperName`}
                                label="Nume shipper"
                                value={bill.shipperName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'shipperName', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <Input 
                                id={`shipperAddress`}
                                label="Adresă shipper"
                                value={bill.shipperAddress}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'shipperAddress', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <Input 
                                id={`shipperPhone`}
                                label="Telefon shipper"
                                value={bill.shipperPhone}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'shipperPhone', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <Input 
                                id={`shipperEmail`}
                                label="Email shipper"
                                value={bill.shipperEmail}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'shipperEmail', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <Input 
                                id={`consigneeName`}
                                label="Nume consignee"
                                value={bill.consigneeName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'consigneeName', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <Input 
                                id={`consigneeAddress`}
                                label="Adresă consignee"
                                value={bill.consigneeAddress}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'consigneeAddress', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <Input 
                                id={`consigneePhone`}
                                label="Telefon consignee"
                                value={bill.consigneePhone}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'consigneePhone', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <Input 
                                id={`consigneeEmail`}
                                label="Email consignee"
                                value={bill.consigneeEmail}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'consigneeEmail', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <Input 
                                id={`notifyName`}
                                label="Nume notify"
                                value={bill.notifyName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'notifyName', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <Input 
                                id={`notifyAddress`}
                                label="Adresă notify"
                                value={bill.notifyAddress}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'notifyAddress', e.target.value, defaultBookingBill as BookingBill)}
                            />                            
                            <Input 
                                id={`notifyPhone`}
                                label="Telefon notify"
                                value={bill.notifyPhone}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'notifyPhone', e.target.value, defaultBookingBill as BookingBill)}
                            />                            
                            <Input 
                                id={`notifyEmail`}
                                label="Email notify"
                                value={bill.notifyEmail}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(bill, setBill, 'notifyEmail', e.target.value, defaultBookingBill as BookingBill)}
                            />                            
                        </div>
                        <div className="gap-4 grid grid-cols-3 mb-2">
                            <TextArea 
                                id={`commodity`}
                                label="Marfă B/L"
                                value={bill.commodity}
                                rows={2}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(bill, setBill, 'commodity', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <TextArea 
                                id={`shortCommodity`}
                                label="Marfă prescurtată"
                                value={bill.shortCommodity}
                                rows={2}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(bill, setBill, 'shortCommodity', e.target.value, defaultBookingBill as BookingBill)}
                            />
                            <TextArea 
                                id={`translatedCommodity`}
                                label="Marfă tradusă"
                                value={bill.translatedCommodity}
                                rows={2}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(bill, setBill, 'translatedCommodity', e.target.value, defaultBookingBill as BookingBill)}
                            />
                        </div>
                        <div className="flex justify-end">
                            <Button size="sm" type="submit">Salvează</Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default BillModal;
