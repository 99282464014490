import { Client } from '../interfaces/Client';
import { formatDateForBackend } from '../functions/dateHelper';
import { Email, Offer, OfferDetail, OfferElement, OfferTemplate } from '../interfaces/Offer';
import client from '../apollo-client';
import { gql } from '@apollo/client'; 

export class OfferService {
  async offerDetails(): Promise<OfferDetail[]> {
    const response = await client.query({
      query: gql`
        query {
            offerDetails {
              id
              name
              category
            }
        }  
      `,
    });
    return response.data.offerDetails;
  }

  async getClients(): Promise<Client[]> {
    const response = await client.query({
      query: gql`
        query {
          clients {
            id
            company
            email
            tradeId
            contacts {
              email
            }
          }
        }
      `,
    });
    return response.data.clients.map((client: Client) => ({
      id: client.id,
      company: client.company,
      tradeId: client.tradeId,
      email: client.email || (client.contacts && client.contacts.length > 0 ? client.contacts[0].email : '')
    }));
  }

  async getOffers(isTemplate : boolean = false, clientId: number|null|undefined = null): Promise<Offer[]> {
    const response = await client.query({
      query: gql`
        query {
          offers (where: {isTemplate: ${isTemplate}${clientId ? `, clientId: ${clientId}` : ``}}){
            id
            clientId
            client {
              id
              company
              email
            }
            user {
              name
            }
            email
            type
            status
            validityStartDate
            validityEndDate
          }
        }
      `,
    });
    return response.data.offers;
  }

  async getOffersForBooking(clientId: number): Promise<Partial<Offer>[]> {
    const response = await client.query({
      query: gql`
        query {
          offers (where: {isTemplate: ${false}, clientId: ${clientId}}){
            id
            validityStartDate
          }
        }
      `,
    });
    if(response.data.offers){
      // response.data.offers.sort((a : Partial<Offer>, b : Partial<Offer>) => b.id! - a.id!);
    }
    return response.data.offers;
  }

  async getOfferTemplates(type: string, userId: number): Promise<OfferTemplate[]> {
    const response = await client.query({
      query: gql`
        query {
          offers (where: {isTemplate: ${true}${ type == 'personal' ? `, userId: ${userId}` : ``}}){
            id
            email
          }
        }
      `,
    });
    return response.data.offers;
  }

  async create(offer: Offer, isTemplate : boolean = false): Promise<Offer> {
    const response = await client.mutate({
      mutation: gql`
        mutation createOffer($input: CreateOfferDto!){
            createOffer(input: $input){
              id
            }
        }
      `,
      variables: {
        input: {
          type: offer.type,
          email: offer.email ? offer.email : null,
          cc: offer.cc ? offer.cc : null,
          bcc: offer.bcc ? offer.bcc : null,
          beginningText: offer.beginningText ? offer.beginningText : null,
          customSubject: offer.customSubject ? offer.customSubject : null,
          tradeId: offer.tradeId ? offer.tradeId : null,
          shippingCurrencyId: offer.shippingCurrencyId ? offer.shippingCurrencyId : null,
          localCurrencyId: offer.localCurrencyId ? offer.localCurrencyId : null,
          autoCurrencyId: offer.autoCurrencyId ? offer.autoCurrencyId : null,
          clientId: offer.clientId !== undefined ? offer.clientId : null,
          validityStartDate: offer.validityStartDate && !isTemplate ? formatDateForBackend(offer.validityStartDate) : null,
          validityEndDate: offer.validityEndDate && !isTemplate ? formatDateForBackend(offer.validityEndDate) : null,
          status: offer.status ? offer.status : 'Creată',
          terminal: offer.terminal ? offer.terminal : null,
          isTemplate: isTemplate,
          localeVat: offer.localeVat,
          navluVat: offer.navluVat,
          compressed: offer.compressed,
          displayDespatch: offer.displayDespatch,
          messageId: offer.messageId,
          containers: offer.containers.map(tag => tag),
          additionalServices: offer.type == "Import" ? offer.additionalServices : null,
          offerDetails: offer.offerDetails.map(detail => ({id: detail.id})),
          offerElements: offer.offerElements.map(element => ({
            offerId: element.offerId,
            name: element.name ? element.name : null,
            shippingFrom: element.shippingFrom,
            shippingTo: element.shippingTo,
            type: element.type,
            price: element.price,
            plusVat: element.plusVat,
            prices: element.prices.map(price => price !== null ? price : null),
          })),
        }
      }
    });
    return response.data.createOffer;
  }

  async update(offer: Offer, isTemplate : boolean = false): Promise<Offer> {
    const response = await client.mutate({
      mutation: gql`
        mutation updateOffer($input: UpdateOfferDto!){
            updateOffer(input: $input){
              id
            }
        }
      `,
      variables: {
        input: {
          id: offer.id,
          type: offer.type,
          email: offer.email ? offer.email : null,
          cc: offer.cc ? offer.cc : null,
          bcc: offer.bcc ? offer.bcc : null,
          beginningText: offer.beginningText ? offer.beginningText : null,
          customSubject: offer.customSubject ? offer.customSubject : null,
          tradeId: offer.tradeId ? offer.tradeId : null,
          shippingCurrencyId: offer.shippingCurrencyId ? offer.shippingCurrencyId : null,
          localCurrencyId: offer.localCurrencyId ? offer.localCurrencyId : null,
          autoCurrencyId: offer.autoCurrencyId ? offer.autoCurrencyId : null,
          clientId: offer.clientId !== undefined ? offer.clientId : null,
          validityStartDate: offer.validityStartDate && !isTemplate ? formatDateForBackend(offer.validityStartDate) : null,
          validityEndDate: offer.validityEndDate && !isTemplate ? formatDateForBackend(offer.validityEndDate) : null,
          status: offer.status ? offer.status : 'Modificată',
          terminal: offer.terminal ? offer.terminal : null,
          isTemplate: isTemplate,
          localeVat: offer.localeVat,
          navluVat: offer.navluVat,
          messageId: offer.messageId,
          compressed: offer.compressed,
          displayDespatch: offer.displayDespatch,
          containers: offer.containers.map(tag => tag),
          additionalServices: offer.type == "Import" ? offer.additionalServices : null,
          offerDetails: offer.offerDetails.map(detail => ({id: detail.id})),
          offerElements: offer.offerElements.map(element => ({
            offerId: element.offerId,
            name: element.name ? element.name : null,
            shippingFrom: element.shippingFrom,
            shippingTo: element.shippingTo,
            type: element.type,
            price: element.price,
            plusVat: element.plusVat,
            prices: element.prices.map(price => price !== null ? price : null),
          })),
        }
      }
    });
    return response.data.updateOffer;
  }

  async getOfferById(id: number): Promise<Offer> {
    const response = await client.query({
      query: gql`
        query {
          offer(id: ${id}) {
            id
            type
            clientId
            client {
              id
              company
              email
            }
            messageId
            validityStartDate
            validityEndDate
            email
            cc
            bcc
            beginningText
            customSubject
            tradeId
            localeVat
            navluVat
            compressed
            displayDespatch
            shippingCurrencyId
            localCurrencyId
            autoCurrencyId
            status
            terminal
            isTemplate
            containers
            additionalServices
            shippingCurrency{
              code
            }
            localCurrency{
              code
            }
            autoCurrency{
              code
            }
            offerDetails {
              id
              name
              content
              category
            }
            offerElements {
              id
              offerId
              name
              shippingFrom
              shippingTo
              type
              price
              plusVat
              prices
            }
          }
        }
      `,
    });
    return {
      ...response.data.offer, 
      validityStartDate: response.data.offer.validityStartDate ? new Date(response.data.offer.validityStartDate) : null,
      validityEndDate: response.data.offer.validityEndDate ? new Date(response.data.offer.validityEndDate) : null
    };
  }

  async getOfferTemplate(id: number): Promise<Offer> {
    const response = await client.query({
      query: gql`
        query {
          offer(id: ${id}) {
            type
            containers
            additionalServices
            terminal
            shippingCurrencyId
            localCurrencyId
            autoCurrencyId
            localeVat
            navluVat
            compressed
            displayDespatch
            cc
            bcc
            beginningText
            customSubject
            offerDetails {
              id
              name
              category
            }
            offerElements {
              name
              shippingFrom
              shippingTo
              type
              price
              plusVat
              prices
            }
          }
        }
      `,
    });
    return {
      ...response.data.offer, 
      offerElements: response.data.offer.offerElements.map((element : OfferElement) => ({
        ...element, offerId: null
      }))
    };
  }

  async deleteOffer(id: number): Promise<boolean> {
    const response = await client.mutate({
      mutation: gql`
        mutation {
          deleteOffer(id: ${id})
        }
      `,
    });
    return response.data.deleteOffer;
  }

  async sendOffer(id: number): Promise<Partial<Offer>> {
    const response = await client.mutate({
      mutation: gql`
        mutation {
          sendOffer(id: ${id})
          {
            id
            status
          }
        }
      `,
    });
    return response.data.sendOffer;
  }

  async cloneOffer(id: number): Promise<Offer> {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            cloneOffer(id: ${id})
            {
              id
              clientId
              client {
                id
                company
                email
              }
              email
              type
              status
              validityStartDate
              validityEndDate
            }
          }
        `,
      });
      return response.data.cloneOffer;
    } catch (error) {
      // console.log(error.errors[0].message);
      throw new Error('Oferta nu a putut fi copiată.');
    }
  }

  async requestRoute(formData : { id: number; pol: string; pod: string; startDate: Date; endDate: Date; }) : Promise<{status: string; message?: string; data: {vessel_name: string; voyage_code: string; tranzit: number; transshipment: string; loading_date: string; discharge_date: string;}[]}>{
    const response = await client.mutate({
      mutation: gql`
        mutation requestRoute($input: [RequestRouteDto!]!){
            requestRoute(input: $input){
              status
              message
            }
        }
      `,
      variables: {
        input: formData
      }
    });
    return response.data.requestRoute;
  }

  async getOfferHTML(id: number): Promise<string> {
    try {
      const response = await client.query({
        query: gql`
          query getOfferHTML($id: Int!) {
            getOfferHTML(id: $id)
          }
        `,
        variables: {
          id: id
        }
      });
      
      return response.data.getOfferHTML || '';
    } catch (error) {
      console.error('Error fetching offer HTML:', error);
      return ''; // sau throw error pentru a-l gestiona în componenta părinte
    }
  }

  async getLastEmails(email: string): Promise<Email[]> {
    try {
      const response = await client.query({
        query: gql`
          query getLastEmails($email: String!) {
            getLastEmails(email: $email){
              id
              threadId
              subject
              from
              to
              date
              body
              snippet
            }
          }
        `,
        variables: {
          email: email
        }
      });
      console.log(response.data.getLastEmails);
      return response.data.getLastEmails;
    } catch (error) {
      throw new Error('Eroare la preluarea email-urilor.');
    }
  }
  
}
