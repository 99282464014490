// src/decorators/validation.decorators.ts

import { IsOptional, IsString, IsInt, IsBoolean, MaxLength, IsNotEmpty, IsNumber } from 'class-validator';

// Helper function pentru a combina decoratorii (similar cu applyDecorators din NestJS)
function composeDecorators(...decorators: PropertyDecorator[]): PropertyDecorator {
    return (target: Object, propertyKey: string | symbol) => {
        decorators.reverse().forEach(decorator => {
            decorator(target, propertyKey);
        });
    };
}

// Decorator pentru câmpuri string opționale
export function CustomStringField(maxLength: number = 255): PropertyDecorator {
    return composeDecorators(
        IsOptional(),
        IsString(),
        MaxLength(maxLength)
    );
}

// Decorator pentru câmpuri string obligatorii
export function CustomRequiredStringField(maxLength: number = 255): PropertyDecorator {
    return composeDecorators(
        IsNotEmpty(),
        IsString(),
        MaxLength(maxLength)
    );
}

// Decorator pentru câmpuri int opționale
export function CustomIntField(): PropertyDecorator {
    return composeDecorators(
        IsInt(),
        IsOptional()
    );
}

// Decorator pentru câmpuri int obligatorii
export function CustomRequiredIntField(): PropertyDecorator {
    return composeDecorators(
        IsInt(),
        IsNotEmpty()
    );
}

// Decorator pentru câmpuri boolean opționale
export function CustomBooleanField(): PropertyDecorator {
    return composeDecorators(
        IsOptional(),
        IsBoolean()
    );
}

// Decorator pentru câmpuri boolean obligatorii
export function CustomRequiredBooleanField(): PropertyDecorator {
    return composeDecorators(
        IsNotEmpty(),
        IsBoolean()
    );
}

// Decorator pentru câmpuri float opționale
export function CustomFloatField(): PropertyDecorator {
    return composeDecorators(
        IsOptional(),
        IsNumber()
    );
}

// Decorator pentru câmpuri float obligatorii
export function CustomRequiredFloatField(): PropertyDecorator {
    return composeDecorators(
        IsNotEmpty(),
        IsNumber()
    );
}