import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaRegChartBar } from "react-icons/fa";
import { TbUsers } from "react-icons/tb";
import CheckPermission from './CheckPermission';
import { IoMdSettings } from 'react-icons/io';
import { PiShippingContainerBold } from "react-icons/pi";
import { RiShip2Fill } from "react-icons/ri";
import { IoDocumentOutline } from 'react-icons/io5';
import { LuTable } from 'react-icons/lu';
import { RiBillLine } from "react-icons/ri";

const BookingsNavBar: React.FC = () => {
  return (
    <div className="hidden bg-softprimary md:flex items-center px-5 text-black text-[0.95rem] font-medium h-9 ps-[155px]">
      <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
        <NavLink to="/bookings/index" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
          <LuTable className='h-4 w-4'/>
          <span className="mr-2">Bookinguri</span>
        </NavLink>
      </CheckPermission>
      <CheckPermission permissions={['MOVEMENTS_VIEW_ALL']}>
        <NavLink to="/bookings/movements" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
          <PiShippingContainerBold className='h-4 w-4'/>
          <span className="mr-2">Mișcări</span>
        </NavLink>
      </CheckPermission>
      <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
        <NavLink to="/bookings/billChecks" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
          <FaRegChartBar className='h-4 w-4'/>
          <span className="mr-2">Verificare</span>
        </NavLink>
      </CheckPermission>
      <CheckPermission permissions={['MOVEMENT_FILES']}>
        <NavLink to="/bookings/movementsConfiguration" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
          <IoMdSettings className='h-4 w-4'/>
          <span className="mr-2">Configurări mișcări</span>
        </NavLink>
      </CheckPermission>
      <NavLink to="/bookings/vessels" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
        <RiShip2Fill className='h-4 w-4'/>
        <span className="mr-2">Nave</span>
      </NavLink>
      <CheckPermission permissions={['MANIFESTS_VIEW_ALL']}>
        <NavLink to="/bookings/manifests" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
          <IoDocumentOutline className='h-4 w-4' />
          <span className="mr-2">Manifeste</span>
        </NavLink>
      </CheckPermission>
      <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
        <NavLink to="/bookings/bills/index" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
          <RiBillLine className='h-4 w-4' />
          <span className="mr-2">B/L's</span>
        </NavLink>
      </CheckPermission>
      <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
        <NavLink to="/bookings/emails/index" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
          <RiBillLine className='h-4 w-4' />
          <span className="mr-2">Emailuri Avizari</span>
        </NavLink>
      </CheckPermission>
    </div>
  );
};

export default BookingsNavBar;