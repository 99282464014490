import { Type } from 'class-transformer';
import { IsDate, IsInt, IsNotEmpty, IsOptional, IsString, MaxLength } from 'class-validator';

export class CreateRoute {
    @IsNotEmpty({ message: 'Tabul Ruta: Câmpul "POL" este obligatoriu.' })
    @IsString({ message: 'Tabul Ruta: Câmpul "POL" trebuie să fie un șir de caractere.' })
    @MaxLength(255, { message: 'Tabul Ruta: Câmpul "POL" trebuie să aibă cel mult 255 de caractere.' })
    from?: string|null;
    
    @IsOptional()
    @IsString({ message: 'Tabul Ruta: Câmpul "POD" trebuie să fie un șir de caractere.' })
    @MaxLength(255, { message: 'Tabul Ruta: Câmpul "POD" trebuie să aibă cel mult 255 de caractere.' })
    to?: string|null;

    @IsOptional()
    @IsString({ message: 'Tabul Ruta: Câmpul "Cod voiaj" trebuie să fie un șir de caractere.' })
    @MaxLength(255, { message: 'Tabul Ruta: Câmpul "Cod voiaj" trebuie să aibă cel mult 255 de caractere.' })
    vesselCode?: string|null;

    @IsOptional()
    @IsString({ message: 'Tabul Ruta: Câmpul "Nume navă" trebuie să fie un șir de caractere.' })
    @MaxLength(255, { message: 'Tabul Ruta: Câmpul "Nume navă" trebuie să aibă cel mult 255 de caractere.' })
    vesselName?: string|null;
    
    @IsOptional()
    @IsDate({ message: 'Tabul Ruta: Câmpul "ETD" trebuie să fie o dată validă.' })
    @Type(() => Date)
    etd?: Date|null;
    
    @IsOptional()
    @IsDate({ message: 'Tabul Ruta: Câmpul "ATD" trebuie să fie o dată validă.' })
    @Type(() => Date)
    atd?: Date|null;
    
    @IsOptional()
    @IsDate({ message: 'Câmpul "ETA" trebuie să fie o dată validă.' })
    @Type(() => Date)
    eta?: Date|null;
    
    @IsOptional()
    @IsDate({ message: 'Câmpul "ATA" trebuie să fie o dată validă.' })
    @Type(() => Date)
    ata?: Date|null;
}

export type RouteCreateForm = InstanceType<typeof CreateRoute>;

export class UpdateRoute extends CreateRoute {
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type RouteUpdateForm = InstanceType<typeof UpdateRoute>;