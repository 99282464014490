
import { IsArray, IsInt, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { CustomBooleanField, CustomFloatField, CustomIntField, CustomStringField } from '../general/customValidators';

export class CreateBookingContainerBooking {
    @CustomStringField()
    containerNumber?: string;

    @CustomStringField()
    size?: string|null;
    
    @CustomStringField()
    type?: string|null;

    @IsArray()
    @IsOptional()
    @IsString({ each: true })
    bls?: string[];
    
    @CustomStringField()
    iso?: string;
    
    @CustomIntField()
    freeDays?: number;
    
    @CustomFloatField()
    tare?: number;
    
    @CustomFloatField()
    weight?: number;
    
    @CustomFloatField()
    vgmNumber?: number;
    
    @CustomFloatField()
    volume?: number;
    
    @CustomFloatField()
    grossWeight?: number;
    
    @CustomStringField()
    sealNumber?: string;
    
    @CustomFloatField()
    packageNumber?: number;
    
    @CustomStringField()
    mrnNumber?: string;
    
    @CustomStringField()
    riNumber?: string;
    
    @CustomStringField()
    customsNumber?: string;
    
    @CustomStringField()
    arrivalCarNumber?: string;
    
    @CustomStringField()
    departureCarNumber?: string;
    
    @CustomStringField()
    reeferTemp?: string;
    
    @CustomStringField()
    imoDetails?: string;
    
    @CustomStringField()
    oogDims?: string;
    
    @CustomStringField()
    category?: string;
    
    @CustomStringField()
    cargoType?: string;
    
    @CustomStringField()
    remarks?: string;

    @CustomBooleanField()
    dangerCargo?: boolean;

    @CustomBooleanField()
    soc?: boolean;
}

export type BookingContainerCreateForm = InstanceType<typeof CreateBookingContainerBooking>;

export class UpdateBookingContainerBooking extends CreateBookingContainerBooking {
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type BookingContainerUpdateForm = InstanceType<typeof UpdateBookingContainerBooking>;