import React, { useMemo } from 'react';
import { Vessel } from '../../../interfaces/Vessel';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_RO } from '../../../functions/langHelper';
import { ColDef } from 'ag-grid-community';
import { formatDateTime } from '../../../functions/dateHelper';
import { Badge } from 'flowbite-react';

interface TableProps {
    vessels: Partial<Vessel>[];
}

const Table: React.FC<TableProps> = ({ vessels }) => {
  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const gridOptions = { rowHeight: 35 };
  const columns = useMemo<ColDef<Partial<Vessel>>[]>(() => [
    { 
        headerName: 'Id',
        field: 'id', 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        floatingFilter: true, 
        minWidth: 100,
        hide: true
    },
    { 
      headerName: 'Terminal', 
      field: 'terminal', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
    },
    {
      headerName: 'Data sosirii',
      field: 'arrivalDate',
      sort: 'asc',
      sortable: true,
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      filterParams: {
          inRangeInclusive: true,
          defaultOption: 'inRange'
      },
      cellStyle: { display: 'flex',  alignItems: 'center' },
      cellRenderer: (params : any) => <div>{ params.value ? (params.data.arrivalActual ? <Badge color="success">{formatDateTime(params.value)}</Badge> : <Badge color="blue">{formatDateTime(params.value)}</Badge>) : '' }</div>
    },
    {
      headerName: 'Data plecării', 
      field: 'departureDate',
      sort: 'asc',
      sortable: true,
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      filterParams: {
          inRangeInclusive: true,
          defaultOption: 'inRange'
      },
      cellStyle: { display: 'flex',  alignItems: 'center' },
      cellRenderer: (params : any) => <div>{ params.value ? (params.data.departureActual ? <Badge color="success">{formatDateTime(params.value)}</Badge> : <Badge color="blue">{formatDateTime(params.value)}</Badge>) : '' }</div>
    },
    { 
      headerName: 'Voiaj', 
      field: 'code', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
    },
    { 
      headerName: 'Cod', 
      field: 'serviceCode', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
    },
    { 
      headerName: 'Disc. Port', 
      field: 'from', 
      sortable: true, 
      filter: 'agSetColumnFilter', 
      floatingFilter: true,
      initialHide: true,
    },
    { 
      headerName: 'Load. Port', 
      field: 'to', 
      sortable: true, 
      filter: 'agSetColumnFilter', 
      floatingFilter: true,
    },
    { 
      headerName: 'Serviciu', 
      field: 'service', 
      sortable: true, 
      filter: 'agSetColumnFilter', 
      floatingFilter: true,
    },
    { 
        headerName: 'Nava', 
        field: 'name', 
        sortable: true, 
        filter: 'agTextColumnFilter', 
        floatingFilter: true,
    },
  ], []);
  
  return (
    <>
        <AgGridReact 
          localeText={AG_GRID_LOCALE_RO}
          columnDefs={columns} 
          rowData={vessels}
          defaultColDef={{ flex: 1, minWidth: 20 }}
          domLayout='autoHeight'
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          gridOptions={gridOptions}
          enableCharts={true}
          enableRangeSelection={true}
          sideBar={true}
        />
    </>
  );
};

export default Table;