import React, { useState, useEffect } from 'react';
import { Button, Modal } from "flowbite-react";
import { Activity, defaultActivity, scheduledContactOptions, priorityOptions } from '../../interfaces/Activity';
import { Client } from '../../interfaces/Client';
import { User } from '../../interfaces/User';
import { ClientService } from '../../services/ClientService';
import { UserService } from '../../services/UserService';
import { handleInputChange, handleSelectChange } from '../../functions/selectHelper';
import DatePicker from 'react-datepicker';
import { getDateFormat } from '../../functions/dateHelper';
import Input from '../../components/Input';
import { ActivityService } from '../../services/ActivityService';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import CustomSelect from '../../components/CustomSelect';
import CustomDatePicker from '../../components/CustomDatePicker';

interface NewActivityModalProps {
    activityId: number | null;
    openModal: boolean;
    setOpenModal: (openModal: boolean) => void;
    onCreated: (activity: Activity, prevActivity: Activity) => void;
}

const NewActivityModal: React.FC<NewActivityModalProps> = ({ activityId, openModal, setOpenModal, onCreated }) => {
    const [activity, setActivity] = useState<Activity>(defaultActivity);
    const [client, setClient] = useState<Client | null>(null);
    const [users, setUsers] = useState<User[]>([]);
    const clientService = new ClientService();
    const activityService = new ActivityService();
    const userService = new UserService();

    useEffect(() => {
        if (activityId && openModal) {
            activityService.find(activityId).then((data) => {
                setActivity({
                    ...defaultActivity,
                    clientId: data.clientId,
                    client: data.client,
                    userId: data.userId,
                    createdFrom: data.id,
                    priority: data.priority,
                    scheduledDate: null,
                    contactId: data.contactId,
                    scheduledContact: data.scheduledContact,
                    description: data.description
                });
                if (data.clientId) {
                    clientService.getClientWithContacts(data.clientId).then((clientData) => {
                        setClient(clientData);
                    });
                }
            });
        }
    }, [activityId, openModal]);

    useEffect(() => {
        if(openModal){
            userService.getUsers().then((data) => setUsers(data));
        }
    }, [openModal]);


    const userOptions = users.map(user => ({ value: user.id, label: user.name }));
    const contactOptions = client?.contacts.map(contact => ({ value: contact.id, label: contact.name })) || [];

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if(activityId){
                const createFromExisting = await activityService.createFromExisting(activity, activityId);
                setOpenModal(false);
                successMessage('Activitate închisă și noua activitate creată cu succes!');
                onCreated(createFromExisting[0], createFromExisting[1]);
            }
        } catch (error) {
            errorMessage('Data planificată este obligatorie!');
        }
    };

    return (
        <Modal show={openModal} size="3xl" popup onClose={() => setOpenModal(false)}>
            <Modal.Header>
                Rezolvă cu un nou contact
            </Modal.Header>
            <Modal.Body className="overflow-visible">
                <div className="space-y-2 pt-5">
                    <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                        <div className="mb-1 col-span-1">
                            <Input
                                id="client"
                                readOnly
                                label="Client"
                                value={activity.client?.company || ''}
                                onChange={() => { }}
                            />
                        </div>
                        <div className="mb-1 col-span-1">
                            <CustomSelect
                                value={userOptions.find(user => user.value === activity.userId)}
                                onChange={(data) => handleSelectChange(activity, setActivity, 'userId', data)}
                                options={userOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Utilizator"
                            />
                        </div>
                        <div className="mb-1 col-span-1">
                            <CustomSelect
                                value={priorityOptions.find(option => option.value === activity.priority)}
                                onChange={(data) => handleSelectChange(activity, setActivity, 'priority', data)}
                                options={priorityOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Prioritate"
                            />
                        </div>
                        <div className="mb-1 col-span-1">
                            <CustomDatePicker
                                selected={activity.scheduledDate ? new Date(activity.scheduledDate) : null}
                                onChange={(date) => setActivity({ ...activity, scheduledDate: date })}
                                dateFormat={getDateFormat()}
                                locale="ro"
                                isClearable
                                placeholderText="Dată planificată"
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div className="mb-1 col-span-1">
                            <CustomSelect
                                value={contactOptions.find(contact => contact.value === activity.contactId)}
                                onChange={(data) => handleSelectChange(activity, setActivity, 'contactId', data)}
                                options={contactOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Contact"
                            />
                        </div>
                        <div className="mb-1 col-span-1">
                            <CustomSelect
                                value={scheduledContactOptions.find(option => option.value === activity.scheduledContact)}
                                onChange={(data) => handleSelectChange(activity, setActivity, 'scheduledContact', data)}
                                options={scheduledContactOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Contact planificat"
                            />
                        </div>
                        <div className="mb-1 col-span-2">
                            <label htmlFor="description" className="block text-sm font-medium text-gray-700">Descriere</label>
                            <textarea
                                id="description"
                                name="description"
                                value={activity.description || ''}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(activity, setActivity, 'description', e.target.value, defaultActivity)}
                                rows={3}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:border-primary sm:text-sm"
                                placeholder="Introduceți descrierea."
                            ></textarea>
                        </div>
                        <div className="col-span-2 flex justify-end">
                            <Button size="sm" type="submit">Salvează și creează nouă activitate</Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default NewActivityModal;
