import React, { useState } from 'react';
import { Booking, getNextStatusName, Route } from '../../../interfaces/Booking';
import { formatDate } from '../../../functions/dateHelper';
import BookingProgress from './components/BookingProgress';
import ModalView from '../../offers/ModalView';
import { Button, Dropdown } from 'flowbite-react';
import Tooltip from '../../../components/Tooltip';
import { NavLink } from 'react-router-dom';
import { BookingService } from '../../../services/BookingService';
import { errorMessage } from '../../../functions/generalHelper';
import ArrivalNoticeModal from '../components/ArrivalNoticeModal';

interface GeneralProps {
  booking: Booking;
  onSave: () => void;
}

const General: React.FC<GeneralProps> = ({ booking, onSave }) => {
  const [isModalViewOpen, setIsModalViewOpen] = useState<boolean>(false);
  const [viewOfferId, setViewOfferId] = useState<number>(0);
  const statusViitor = getNextStatusName(booking.status, booking.shippingType!);
  const bookingService = new BookingService();
  const [showArrivalNoticeModal, setShowArrivalNoticeModal] = useState(false);
  const [documentType, setDocumentType] = useState<string>('');

  const changeStatus = (status : string|null) => {
    try {
      bookingService.changeBookingStatus(booking.id!, status!).then(() => {onSave()});
    } catch (error) {
      errorMessage('Statusul bookingului nu a putut fi actualizat!');
    }
  }

  return (
    <>
      <ArrivalNoticeModal setDocumentType={setDocumentType} documentType={documentType} type="booking" ids={[booking.id!]} showModal={showArrivalNoticeModal} setShowModal={setShowArrivalNoticeModal} />
      <ModalView id={viewOfferId} openModal={isModalViewOpen} setOpenModal={setIsModalViewOpen} />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="rounded-lg border border-gray-200 bg-white p-5 relative">
          <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Informații de bază</h4>
          <p><strong>ID:</strong> {booking.id}</p>
          <p><strong>Număr:</strong> {booking.number}</p>
          <p><strong>Status:</strong> {booking.status}</p>
          <p><strong>Tip expediere:</strong> {booking.shippingType}</p>
          <p><strong>Tip încărcare:</strong> {booking.loadType}</p>
          <p><strong>Tip booking:</strong> {booking.bookingType}</p>
          <p><strong>B/L's:</strong> {booking.bookingBills ? booking.bookingBills.map(bl => bl.code).join(', ') : ''}</p>

          {
            statusViitor ? <>
              <div className="mt-2">
                <Tooltip content="Modifică status">
                  <Button onClick={() => {changeStatus(statusViitor)}} size="sm">{ statusViitor }</Button>
                </Tooltip>
              </div>
            </> : <></>
          }
        </div>

        <div className="rounded-lg border border-gray-200 bg-white p-5 relative">
          <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Termeni și condiții</h4>
          <p><strong>Nume linie de transport:</strong> {booking.shippingLineName}</p>
          <p><strong>Marfă:</strong> {booking.commodity}</p>
          <p><strong>Ofertă:</strong> {booking.offerId ? <>
            <button onClick={() => {setViewOfferId(booking.offerId!); setIsModalViewOpen(true)}} className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline">{booking.offerId}(Vezi ofertă)</button>
          </> : ''}</p>
          <p><strong>Termen serviciu origine:</strong> {booking.svcTermOrigin}</p>
          <p><strong>Termen serviciu destinație:</strong> {booking.svcTermDestination}</p>
          <p><strong>Status blocare:</strong> {booking.blockStatus}</p>
          {/* <p><strong>În așteptare:</strong> {booking.waiting}</p> */}
          {/* <p><strong>Blocare încărcare:</strong> {booking.blockStowage}</p> */}
          <div className="mt-2">
            <Dropdown label="Trimite document" size="sm">
              <Dropdown.Item onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('arrivalNotice')}}>Preavizare</Dropdown.Item>
              <Dropdown.Item onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('arrivalUpdate')}}>Avizare</Dropdown.Item>
              <Dropdown.Item onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('deliveryOrder')}}>Liber de vamă</Dropdown.Item>
            </Dropdown>
          </div>
        </div>

        <div className="rounded-lg border border-gray-200 bg-white p-5 relative">
          <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Date administrative</h4>
          <p><strong>Data creării:</strong> {formatDate(booking.creationDate)}</p>
          <p><strong>Creat de:</strong> {booking.createdBy}</p>
          <p><strong>ID Client:</strong> {booking.clientId}</p>
          <p><strong>ID Ofertă:</strong> {booking.offerId}</p>
          <p><strong>Creat la:</strong> {booking.createdAt ? formatDate(booking.createdAt) :''}</p>
          <p><strong>Actualizat la:</strong> {booking.updatedAt ? formatDate(booking.updatedAt) :''}</p>
          <div className="mt-2">
            <Tooltip content="Editează booking">
              <NavLink to={`/bookings/form/${booking.id}`}>
                <Button size="sm">Editează booking</Button>
              </NavLink>
            </Tooltip>
          </div>
        </div>

        {booking.status === 'Cancelled' && (
          <div className="rounded-lg border border-gray-200 bg-white p-5 relative">
            <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Informații anulare</h4>
            <p><strong>Motiv anulare:</strong> {booking.cancelReason}</p>
            <p><strong>Data anulării:</strong> {formatDate(booking.cancelledDate)}</p>
          </div>
        )}

        <div className="rounded-lg border border-gray-200 bg-white p-5 relative col-span-full">
          <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Rute</h4>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">De la</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Către</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Denumire vas</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cod vas</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ETD</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ATD</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ETA</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ATA</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {booking.routes.map((route: Route, index: number) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">{route.from}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{route.to}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{route.vesselName}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{route.vesselCode}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatDate(route.etd)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatDate(route.atd)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatDate(route.eta)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatDate(route.ata)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="lg:col-span-3 md:col-span-2 col-span-1">
          <BookingProgress currentStatus={booking.status} isExport={booking.shippingType!.includes('Export') ? true : false} />
        </div>
      </div>
    </>
  );
};

export default General;