import React, { useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueSetterParams } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { defaultBookingBill } from '../../../../interfaces/Booking';
import { CellRenderer } from '../../../../functions/agGridHelper';
import Actions from '../../../../components/Actions';
import { AG_GRID_LOCALE_RO } from '../../../../functions/langHelper';
import { FaRegCopy } from 'react-icons/fa';
import { successMessage, warningMessage } from '../../../../functions/generalHelper';
import Tooltip from '../../../../components/Tooltip';
import { formatDate } from '../../../../functions/dateHelper';
import { BookingUpdateForm } from '../../../../validations/booking/CreateBooking';
import { UpdateBookingBillBooking } from '../../../../validations/booking/CreateBookingBillBooking';

interface BillsTableProps {
  booking: Partial<BookingUpdateForm>;
  setBooking: React.Dispatch<React.SetStateAction<Partial<BookingUpdateForm>>>;
  isEdit: number;
  setIsEdit: React.Dispatch<React.SetStateAction<number>>;
  setNewBill: React.Dispatch<React.SetStateAction<UpdateBookingBillBooking>>;
}

const BillsTable: React.FC<BillsTableProps> = ({ booking, setBooking, isEdit, setIsEdit, setNewBill }) => {
    const handleEditBill = (index: number) => {
        setIsEdit(index);
        setNewBill(booking.bookingBills![index]);
    };

    const onClone = (id: number) => {
        const bill = booking.bookingBills![id];
        if (bill) {
          setBooking(prev => ({
            ...prev,
            bookingBills: [...prev.bookingBills!, { ...bill, code: '', id: 0 }]
          }));
          warningMessage('Bill clonat cu succes, insa trebuie sa ii introduceti un numar!');
        }
    }

    const handleDeleteBill = useCallback((index: number) => {
      const updatedBookingBills = booking.bookingBills!.filter((_, i) => i !== index);
      setBooking({ ...booking, bookingBills: updatedBookingBills });
      if (index === isEdit) {
          setIsEdit(-1);
          setNewBill(defaultBookingBill as UpdateBookingBillBooking);
      }
    },[booking.bookingBills]);
    
    const columns: ColDef<UpdateBookingBillBooking>[] = useMemo(() => [
      { 
        headerName: 'Număr', 
        field: 'code', 
        editable: true,
        cellClass: 'group',
        minWidth: 160, 
        cellRenderer: CellRenderer, 
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'code');
          return true; 
        },
      },
      { 
        headerName: 'Dată', 
        field: 'date',
        minWidth: 130,
        cellRenderer: (params : any) => {
          return params.value ? formatDate(params.value) : '';
        }
      },
      { 
        headerName: 'Terminal', 
        field: 'terminal', 
        minWidth: 130,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'terminal');
          return true; 
        },
      },
      { 
        headerName: 'Tip eliberare', 
        field: 'releaseType', 
        minWidth: 130,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'releaseType');
          return true; 
        },
      },
      { 
        headerName: 'Nume Shipper', 
        field: 'shipperName', 
        minWidth: 130,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'shipperName');
          return true; 
        },
      },
      { 
        headerName: 'Adresă Shipper', 
        field: 'shipperAddress', 
        minWidth: 130,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'shipperAddress');
          return true; 
        },
      },
      { 
        headerName: 'Nume Consignee', 
        field: 'consigneeName', 
        minWidth: 130,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'consigneeName');
          return true; 
        },
      },
      { 
        headerName: 'Adresă Consignee', 
        field: 'consigneeAddress', 
        minWidth: 130,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'consigneeAddress');
          return true; 
        },
      },
      { 
        headerName: 'Marfă', 
        field: 'commodity', 
        minWidth: 130,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'commodity');
          return true; 
        },
      },
      { 
        headerName: 'HS Code', 
        field: 'hsCode', 
        minWidth: 130,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'hsCode');
          return true; 
        },
      },
      { 
        headerName: 'Loc încărcare', 
        field: 'loadingPlace', 
        minWidth: 130,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'loadingPlace');
          return true; 
        },
      },
      {
        headerName: 'Acțiuni',
        minWidth: 100,
        sortable: false,
        filter: false,
        cellRenderer: (params: any) => (
          <>
            <Actions
              id={params.node.rowIndex}
              onEdit={() => handleEditBill(params.node.rowIndex)}
              onDelete={() => handleDeleteBill(params.node.rowIndex)}
              moduleName="B/L">
              <Tooltip content="Clonează B/L">
                <button type="button" className="text-orange-400 hover:text-orange-600 h-[20px] mt-1 flex items-center mx-1" aria-label="Info" onClick={() => onClone(params.rowIndex)}>
                  <FaRegCopy className="w-[17px] h-[17px] hover:scale-110 duration-75"/>
                </button>
              </Tooltip>
            </Actions>
          </>
        )
      }
    ], [booking.bookingBills]);

    const modificaCelula = async (params : any, coloana : string) => {
      // @ts-ignore
      params.data[coloana] = params.newValue ? params.newValue : '';
      
      setBooking(prev => ({
        ...prev,
        bookingBills: prev.bookingBills!.map((bill, index) =>
          index === params.node.rowIndex
            ? { ...bill, [coloana]: params.newValue ? params.newValue : '' }
            : bill
        )
      }));
      if(coloana === 'code') {
        warningMessage('Daca aveti containere pe acest bill, va trebui sa le modificati manual!');
      }else{
        successMessage('Bill editat cu succes!');
      }
    }

    return (
        <div className="ag-theme-quartz">
            <AgGridReact 
              localeText={AG_GRID_LOCALE_RO}
              columnDefs={columns} 
              rowData={booking.bookingBills}
              defaultColDef={{ flex: 1, minWidth: 2 }}
              domLayout='autoHeight'
              pagination={false}
              gridOptions={{rowHeight: 35}}
              enableRangeSelection={true}
              // onCellValueChanged={onCellValueChanged}
              suppressClickEdit={true}
              stopEditingWhenCellsLoseFocus={true}
            />
        </div>
    );
};

export default BillsTable;