// formHandlers.ts
import { Dispatch, SetStateAction } from 'react';

export const handleInputChange = <T>(
  data: T,
  setData: Dispatch<SetStateAction<T>>,
  id: keyof T,
  value: any,
  defaultData?: T
) => {
  if(defaultData == undefined && value == null){
    return;
  }
  if (value == null) {
    value = defaultData![id];
  }
  setData({ ...data, [id]: value });
};

export const handleMultipleSelectChange = <T>(
  data: T,
  setData: Dispatch<SetStateAction<T>>,
  field: keyof T,
  selectedOptions: any
) => {
  const selectedOpt = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
  setData({ ...data, [field]: selectedOpt });
};

export const handleSelectChange = <T>(
  data: T,
  setData: Dispatch<SetStateAction<T>>,
  field: keyof T,
  selectedOption: any
) => {
  setData({ ...data, [field]: selectedOption.value });
};

export const handleCreatableChange = <T>(
  data: T,
  setData: Dispatch<SetStateAction<T>>,
  field: keyof T,
  selectedOption: any
) => {
  setData({ ...data, [field]: selectedOption.value });
};

export const handleMultipleCreatableChange = <T>(
  data: T,
  setData: Dispatch<SetStateAction<T>>,
  field: keyof T,
  selectedOptions: any
) => {
  const selectedOpt = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
  setData({ ...data, [field]: selectedOpt });
};
