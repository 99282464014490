import React, { useState, useEffect, useCallback } from 'react';
import { Button, Tabs } from "flowbite-react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { defaultBooking } from '../../interfaces/Booking';
import { BookingService } from '../../services/BookingService';
import { RecommendationResp } from '../../interfaces/Recommandation';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import { HiUserCircle, HiClipboardList } from "react-icons/hi";
import { MdDashboard } from "react-icons/md";
import GeneralTab from './formTabs/GeneralTab';
import RouteTab from './formTabs/RouteTab';
import ContainersTab from './formTabs/ContainersTab';
import { RecommandationService } from '../../services/RecommandationService';
import renderSkeleton from '../../components/SkeletonLoader';
import BillsTab from './formTabs/BillTab';
import { BookingUpdateForm } from '../../validations/booking/CreateBooking';

const Form: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const [loading, setLoading] = useState<boolean>(true);
    const [booking, setBooking] = useState<Partial<BookingUpdateForm>>(defaultBooking);
    const bookingService = new BookingService();
    const navigate = useNavigate();
    const [recommendations, setRecommendations] = useState<RecommendationResp[]>([]);
    const recommendationsService = new RecommandationService();

    // GET ALL OPTION VALUES AND FIELD RECOMMENDATIONS
    useEffect(() => {
      recommendationsService.getRecommendations().then(data => setRecommendations(data));
    }, [recommendationsService]);
  
    useEffect(() => {
      if (id) {
        setBooking({...defaultBooking, containers: [], routes: []});
        bookingService.getBookingForEdit(parseInt(id)).then((data) => {setBooking(data); setLoading(false)});
        setLoading(false);
      }else{
        setBooking({...defaultBooking, containers: [], routes: []});
        setLoading(false);
      }
    }, [id]);
    
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        const response = id ? await bookingService.updateExport(booking) : await bookingService.create(booking);
        if(response.status === 'success'){
          successMessage(response.message);
          navigate('/bookings/index');
        }else{
          errorMessage(response.message);
        }
      } catch (error) {
        // errorMessage(error instanceof Error ? error.message : 'Vă rugăm să completați toate câmpurile obligatorii!');
      }
    }

    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLFormElement>) => {
      if (e.key === 'Enter' && e.target instanceof HTMLInputElement) {
        e.preventDefault();
        console.log('Enter apăsat, formularul NU a fost trimis.');
      }
    }, []);
    
    return loading || !booking ? renderSkeleton() : (
        <>
            <div className='px-3'>
                <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                    {
                      id ? <>
                        <Tabs aria-label="Tabs with icons" className="pt-2">
                          <Tabs.Item active title="Date generale" icon={HiUserCircle}>
                            <GeneralTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item>
                          <Tabs.Item title="Ruta" icon={MdDashboard}>
                            <RouteTab booking={booking} setBooking={setBooking} />
                          </Tabs.Item>
                          <Tabs.Item title="B/L's" icon={HiClipboardList}>
                            <BillsTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item>
                          <Tabs.Item title="Containere" icon={HiClipboardList}>
                            <ContainersTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item>
                        </Tabs>
                      </> : <>
                        <Tabs aria-label="Tabs with icons" className="pt-2">
                          <Tabs.Item active title="Date generale" icon={HiUserCircle}>
                            <GeneralTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item>
                          <Tabs.Item title="Ruta" icon={MdDashboard}>
                            <RouteTab booking={booking} setBooking={setBooking} />
                          </Tabs.Item>
                          <Tabs.Item title="B/L's" icon={HiClipboardList}>
                            <BillsTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item>
                          <Tabs.Item title="Containere" icon={HiClipboardList}>
                            <ContainersTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item>
                          {/* <Tabs.Item title="Cargo Manifest" icon={HiClipboardList}>
                            <CargoManifestTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item> */}
                        </Tabs>
                      </>
                    }

                    <div className="flex justify-end space-x-4 mt-3">
                      <NavLink to="/bookings/index">
                        <Button size="sm" color="gray">Înapoi</Button>
                      </NavLink>
                      <Button size="sm" type="submit">Salvează</Button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Form;