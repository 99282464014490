import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Table from './Table';
import { ClientService } from '../../services/ClientService';
import { Client } from '../../interfaces/Client';
import { Button, Tooltip } from "flowbite-react";
import 'react-loading-skeleton/dist/skeleton.css';
import renderSkeleton from '../../components/SkeletonLoader';
import ModalView from './ModalView';
import DeleteModal from '../../components/DeleteModal';
import CheckPermission from '../../components/CheckPermission';
import EmailModal from '../../components/EmailModal';

const Index: React.FC = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [modalViewOpen, setModalViewOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const clientService = new ClientService();
  const navigate = useNavigate();

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    setLoading(true);
    const data = await clientService.getClients();
    setClients(data);
    setLoading(false);
  };

  const handleEdit = (id: number) => {
    navigate('/crm/clients/form/' + id);
  };

  const handleDelete = (id: number) => {
    setClientToDelete(id);
    setShowModalDelete(true);
  };

  const handleView = (id: number) => {
    setClientToDelete(id);
    setModalViewOpen(true);
  };

  const onEmailModal = (id: number) => {
    setClientToDelete(id);
    setEmailModalOpen(true);
  };

  const confirmDelete = async () => {
    if (clientToDelete !== null) {
      await clientService.deleteClient(clientToDelete);
      setShowModalDelete(false);
      setClients(clients.filter(client => client.id !== clientToDelete));
      setClientToDelete(0);
    }
  };

  return (
    <div className="ag-theme-quartz px-4">
      <div className="flex justify-between items-center py-2">
      <div className="text-lg font-bold">
        Clienți
      </div>
    
      <Tooltip content="Adaugă client">
        <NavLink to="/crm/clients/form">
          <Button size="sm">Adaugă client</Button>
        </NavLink>
      </Tooltip>

    </div>
      {loading ? ( renderSkeleton() ) : (
        <>
          <Table clients={clients} onEdit={handleEdit} onDelete={handleDelete} onView={handleView} onEmailModal={onEmailModal} />
          <CheckPermission permissions={['CLIENT_DELETE']}>
            <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={confirmDelete} deleteText='Sunteți sigur că doriți să eliminați acest client?' />
          </CheckPermission>
          <ModalView key={`view-client-${clientToDelete}`} showModal={modalViewOpen} setShowModal={setModalViewOpen} id={clientToDelete} />
        </>
      )}
    </div>
  );
};

export default Index;
