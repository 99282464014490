import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useUser } from '../../../components/UserContext';
import Breadcrumb from '../../../components/BreadCrumb';
import BookingsNavBar from '../../../components/BookingsNavBar';
import Index from './Index';
import IndexServiceLoop from './ServiceLoop';

const Vessels: React.FC = () => {
    const { user } = useUser();

    return (
      <>
        <BookingsNavBar />
        <Breadcrumb />
        <Routes>
          <Route path="index" element={<Index />} />
          {/* <Route path="services" element={<IndexServiceLoop />} /> */}
          <Route path="/" element={<Navigate to="/bookings/vessels/index" replace />} />
        </Routes>
      </>
    );
};

export default Vessels;