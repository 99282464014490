import React, { useEffect, useState } from 'react';
import { Modal, Table, TextInput, Pagination, Button } from 'flowbite-react';
import { HiOutlineSearch } from 'react-icons/hi';
import EmailContentModal from './EmailContentModal';
import { OfferService } from '../../../services/OfferService';
import { Email } from '../../../interfaces/Offer';
import { errorMessage } from '../../../functions/generalHelper';
import Loader from '../../../components/CustomLoader';

interface EmailInboxModalProps {
  show: boolean;
  onClose: () => void;
  email: string;
  onSelect: (email : Email) => void;
}

// Funcție utilă pentru parsarea HTML-ului
const parseHtmlToText = (html: string): string => {
  if (!html) return '';
  
  // Creează un element temporar
  const doc = new DOMParser().parseFromString(html, 'text/html');
  
  // Extrage textul fără HTML tags
  return doc.body.textContent || '';
};

const MailModal: React.FC<EmailInboxModalProps> = ({ show, onClose, email, onSelect }) => {
  // State
  const [searchTerm, setSearchTerm] = useState('');
  const [emails, setEmails] = useState<Email[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEmail, setSelectedEmail] = useState<Email | null>(null);
  const [showEmailContent, setShowEmailContent] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const offerService = new OfferService();

  useEffect(() => {
    if(show){
      setIsLoading(true);
      offerService.getLastEmails(email)
        .then(response => setEmails(response))
        .catch(err => {errorMessage('Emailurile nu au putut fi selectate. Contactati administratorul.'); onClose();})
        .finally(() => {setIsLoading(false)});
    }
  }, [email, show]);

  // Filter emails based on search
  const filteredEmails = emails.filter((email) =>
    email.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
    email.from.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination
  const itemsPerPage = 20;
  const totalPages = Math.ceil(filteredEmails.length / itemsPerPage);

  // Get current page emails
  const getCurrentPageEmails = () => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredEmails.slice(start, end);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);
   
    // Formatare pentru oră
    const timeStr = date.toLocaleTimeString('ro-RO', {
      hour: '2-digit',
      minute: '2-digit'
    });
   
    // Verifică dacă e din ziua curentă
    if (date.toDateString() === now.toDateString()) {
      return `Azi, ${timeStr}`;
    }
   
    // Verifică dacă e din ziua precedentă
    if (date.toDateString() === yesterday.toDateString()) {
      return `Ieri, ${timeStr}`;
    }
   
    // Pentru restul datelor
    if (date.getFullYear() === now.getFullYear()) {
      // Dacă e din anul curent, afișează doar ziua și luna
      return date.toLocaleDateString('ro-RO', {
        day: 'numeric',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
   
    // Pentru date din alți ani
    return date.toLocaleDateString('ro-RO', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
   };

  const handleRowClick = (email: Email) => {
    setSelectedEmail(email);
    setShowEmailContent(true);
  };

  // Truncate body text to first 40 characters, after removing HTML
  const truncateBody = (body: string) => {
    const plainText = parseHtmlToText(body);
    return plainText.length > 40 ? plainText.substring(0, 40) + '...' : plainText;
  };

  return (
    <>
      <Modal
        show={show}
        onClose={onClose}
        size="7xl"
      >
        <Modal.Header>
          Inbox
        </Modal.Header>
        <Modal.Body>
          <Loader isLoading={isLoading} />
          <div className="space-y-4">
            {/* Search bar */}
            <div className="flex justify-between items-center gap-4">
              <div className="flex-1">
                <TextInput
                  icon={HiOutlineSearch}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Caută în emailuri..."
                />
              </div>
            </div>

            {/* Emails table */}
            <table className="w-full border-collapse text-sm">
              <thead>
                <tr className="bg-gray-50 text-left">
                  <th className="border-b px-2 py-1">De la</th>
                  <th className="border-b px-2 py-1">Subiect</th>
                  <th className="border-b px-2 py-1">Conținut</th> 
                  <th className="border-b px-2 py-1 min-w-[150px]">Data</th>
                  <th className="border-b px-2 py-1">Selectează</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageEmails().map((email) => (
                  <tr 
                    key={email.id}
                    className={`${!email.read ? 'font-semibold bg-gray-50' : ''} cursor-pointer hover:bg-gray-100`}
                    onClick={() => handleRowClick(email)}
                  >
                    <td className="border-b px-2 py-1">{email.from}</td>
                    <td className="border-b px-2 py-1">
                      <div className="flex items-center gap-2">
                        {email.subject}
                      </div>
                    </td>
                    <td className="border-b px-2 py-1">
                      <div className="text-gray-500">
                        {truncateBody(email.body)}
                      </div>
                    </td>
                    <td className="border-b px-2 py-1">{formatDate(email.date)}</td>
                    <td className="border-b px-2 py-1">
                      <Button 
                        size="sm" 
                        onClick={(e : any) => {
                          e.stopPropagation();
                          onSelect(email);
                        }}>Selectează</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination */}
            <div className="flex justify-center">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
                showIcons
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <EmailContentModal
        show={showEmailContent}
        onClose={() => setShowEmailContent(false)}
        email={selectedEmail}
      />
    </>
  );
};

export default MailModal;