import React, { useEffect, useState } from 'react';
import Input from "../../../components/Input";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { handleCreatableChange, handleInputChange } from "../../../functions/selectHelper";
import { defaultRoute } from "../../../interfaces/Booking";
import { errorMessage } from '../../../functions/generalHelper';
import { Button } from 'flowbite-react';
import { getDateFormat } from '../../../functions/dateHelper';
import DynamicPortSelect from '../../../components/DynamicPortSelect';
import { Vessel } from '../../../interfaces/Vessel';
import { VesselService } from '../../../services/VesselService';
import CustomCreatable from '../../../components/CustomCreatable';
import RoutesTable from './components/RoutesTable';
import { BookingUpdateForm } from '../../../validations/booking/CreateBooking';
import { UpdateRoute } from '../../../validations/booking/CreateRoute';

interface TabProps {
  booking: Partial<BookingUpdateForm>;
  setBooking: React.Dispatch<React.SetStateAction<Partial<BookingUpdateForm>>>;
}

const RouteTab: React.FC<TabProps> = ({ booking, setBooking }) => {
  const [newRoute, setNewRoute] = useState<UpdateRoute>(defaultRoute as UpdateRoute);
  const [isEdit, setIsEdit] = useState<number>(10000);
  const [vesselCodeOptions, setVesselCodeOptions] = useState<Vessel[]>([]);
  const vesselService = new VesselService();

  useEffect(() => {
    vesselService.getPortVessels().then(data => setVesselCodeOptions(data));
  }, []);

  useEffect(() => {
    if(booking.shippingType!.includes('Export') && booking.routes!.length === 0){
      setNewRoute({...newRoute, from: 'ROCND'});
    }
  }, [booking.shippingType, booking.routes]);

  // add and edit functions:
  const handleAddOrEditRoute = () => {
    if(newRoute.vesselCode?.trim() === '') {
      errorMessage('Codul vasului este obligatoriu!');
      return;
    }

    if(isEdit !==10000){
      setBooking((prevBooking) => {
        const updatedRoute = [...prevBooking.routes!];
        updatedRoute[isEdit] = newRoute;
        return { ...prevBooking, routes: updatedRoute };
      });
        setIsEdit(10000);
    }else{
      setBooking((prevBooking) => ({...prevBooking, routes: [...prevBooking.routes!, newRoute] })); 
    }
    setNewRoute(defaultRoute as UpdateRoute);
  }

  const handleResetRoute = () => {
    setIsEdit(10000);
    setNewRoute(defaultRoute as UpdateRoute);
  }

  return (
    <div>
        <div className="rounded-lg border border-gray-200 bg-white p-5 relative mb-6">
            <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Rute</h4>
            <RoutesTable booking={booking} isEdit={isEdit} setBooking={setBooking} setIsEdit={setIsEdit} setNewRoute={setNewRoute} />
        </div>
        <div className="rounded-lg border border-gray-200 bg-white p-5 relative mb-6">
            <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">{isEdit !==10000 ? 'Editează Rută' : 'Adaugă Rută'}</h4>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
              <Input 
                id="vesselName" 
                label="Nume navă"
                value={newRoute.vesselName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newRoute, setNewRoute, 'vesselName', e.target.value, defaultRoute as UpdateRoute)}
              />
              <CustomCreatable
                  value={newRoute.vesselCode && newRoute.vesselCode !=='' ? { value: newRoute.vesselCode, label: newRoute.vesselCode } : null}
                  onChange={(data) => { 
                    handleCreatableChange(newRoute, setNewRoute, 'vesselCode', data);
                    if(data){
                      const vessel = vesselCodeOptions.find(item => item.code === data.value);
                      if(vessel){ 
                        setNewRoute(prev => ({
                          ...prev, 
                          vesselName: vessel.name, 
                          vesselCode: vessel.code, 
                          from: vessel.from, 
                          to: vessel.to, 
                          etd: vessel.etd ? new Date(vessel.etd) : null, 
                          atd: vessel.atd ? new Date(vessel.atd) : null, 
                          eta: vessel.eta ? new Date(vessel.eta) : null, 
                          ata: vessel.ata ? new Date(vessel.ata) : null
                        })); 
                      }
                    }
                  }}
                  options={vesselCodeOptions.filter(item => item.terminal).map(item => ({ value: item.code, label: `${item.code} - ${item.terminal}` }))} 
                  placeholder="Cod voiaj"
              />
              <DynamicPortSelect
                placeholder="POL"
                value={newRoute.from ? newRoute.from : ''}
                onChange={(newValue) => setNewRoute(prev => ({...prev, from: newValue.value}))}
              />
              <DynamicPortSelect
                placeholder="POD"
                value={newRoute.to ? newRoute.to : ''}
                onChange={(newValue) => setNewRoute(prev => ({...prev, to: newValue.value}))}
              />
              <div>
                  <CustomDatePicker
                    selected={newRoute.etd}
                    onChange={(date) => setNewRoute({...newRoute, etd: date })} 
                    dateFormat={getDateFormat()}
                    locale="ro"
                    isClearable
                    placeholderText="ETD"
                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
              </div>
              <div>
                  <CustomDatePicker
                    selected={newRoute.atd}
                    onChange={(date) => setNewRoute({...newRoute, atd: date })} 
                    dateFormat={getDateFormat()}
                    locale="ro"
                    isClearable
                    placeholderText="ATD"
                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
              </div>
              <div>
                  <CustomDatePicker
                    selected={newRoute.eta}
                    onChange={(date) => setNewRoute({...newRoute, eta: date })} 
                    dateFormat={getDateFormat()}
                    locale="ro"
                    isClearable
                    placeholderText="ETA"
                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
              </div>
              <div>
                  <CustomDatePicker
                    selected={newRoute.ata}
                    onChange={(date) => setNewRoute({...newRoute, ata: date })} 
                    dateFormat={getDateFormat()}
                    locale="ro"
                    isClearable
                    placeholderText="ATA"
                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
              </div>
            </div>
            <div className="flex justify-end space-x-4 mt-3">
                <Button size="sm" color="gray" onClick={handleResetRoute}>{isEdit !==10000 ? 'Renunță la editare' : 'Resetează câmpuri'}</Button>
                <Button size="sm" onClick={handleAddOrEditRoute}>{isEdit !==10000 ? 'Editează rută' : 'Adaugă Rută'}</Button>
            </div>
        </div>
    </div>
  );
};

export default RouteTab;