import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, IRichCellEditorParams, ValueSetterParams } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { defaultContainer } from '../../../../interfaces/Booking';
import { CellRenderer } from '../../../../functions/agGridHelper';
import Actions from '../../../../components/Actions';
import { AG_GRID_LOCALE_RO } from '../../../../functions/langHelper';
import { successMessage } from '../../../../functions/generalHelper';
import { BookingUpdateForm } from '../../../../validations/booking/CreateBooking';
import { UpdateBookingContainerBooking } from '../../../../validations/booking/CreateBookingContainerBooking';

interface ContainersTableProps {
  booking: Partial<BookingUpdateForm>;
  setBooking: React.Dispatch<React.SetStateAction<Partial<BookingUpdateForm>>>;
  isEdit: number;
  setIsEdit: React.Dispatch<React.SetStateAction<number>>;
  setNewContainer: React.Dispatch<React.SetStateAction<UpdateBookingContainerBooking>>;
}

const ContainersTable: React.FC<ContainersTableProps> = ({ booking, setBooking, isEdit, setIsEdit, setNewContainer }) => {
    const handleEditContainer = (index: number) => {
        setIsEdit(index);
        setNewContainer(booking.containers![index]);
    };

    const handleDeleteContainer = useCallback((index: number) => {
      const updatedContainers = booking.containers!.filter((_, i) => i !== index);
      // use prev
      setBooking(prev => ({ ...prev, containers: updatedContainers }));
      if (index === isEdit) {
          setIsEdit(-1);
          setNewContainer(defaultContainer as UpdateBookingContainerBooking);
      }
    },[booking.containers]);
    
    const columns: ColDef<UpdateBookingContainerBooking>[] = useMemo(() => [
      { 
        headerName: 'Număr unic', 
        field: 'containerNumber', 
        editable: true,
        cellClass: 'group',
        minWidth: 140, 
        cellRenderer: CellRenderer, 
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'containerNumber');
          return true; 
        },
      },
      { 
        headerName: 'Dimensiune', 
        field: 'size',
        minWidth: 80,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'size');
          return true; 
        },
      },
      { 
        headerName: 'Tip', 
        field: 'type', 
        minWidth: 80,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'type');
          return true; 
        },
      },
      {
        headerName: "BL's",
        field: "bls",
        minWidth: 140,
        cellEditor: "agRichSelectCellEditor",
        editable: true,
        cellEditorParams: {
          values: booking.bookingBills!.map(bill => bill.code),
          multiSelect: true,
          searchType: "matchAny",
          filterList: true,
          highlightMatch: true,
          valueListMaxHeight: 220,
        } as IRichCellEditorParams,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'bls');
          return true; 
        },
      },
      { 
        headerName: 'ISO', 
        field: 'iso', 
        minWidth: 80,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'iso');
          return true; 
        },
      },
      { 
        headerName: 'Zile libere', 
        field: 'freeDays', 
        minWidth: 80,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'freeDays');
          return true; 
        },
      },
      { 
        headerName: 'Tara', 
        field: 'tare', 
        minWidth: 80,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'tare');
          return true; 
        },
      },
      { 
        headerName: 'Greutate Totală', 
        field: 'weight', 
        minWidth: 100,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'weight');
          return true; 
        },
      },
      { 
        headerName: 'VGM', 
        field: 'vgmNumber', 
        minWidth: 100,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'vgmNumber');
          return true; 
        },
      },
      { 
        headerName: 'Volum', 
        field: 'volume', 
        minWidth: 100,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'volume');
          return true; 
        },
      },
      {
        headerName: 'Acțiuni',
        minWidth: 100,
        cellRenderer: (params: any) => (
          <>
            <Actions
              id={params.node.rowIndex}
              onEdit={() => handleEditContainer(params.node.rowIndex)}
              onDelete={() => handleDeleteContainer(params.node.rowIndex)}
              moduleName="container"
            />
          </>
        )
      },
      { 
        headerName: 'Greutate brută', 
        field: 'grossWeight', 
        minWidth: 100,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'grossWeight');
          return true; 
        },
      },
      { 
        headerName: 'Număr sigiliu', 
        field: 'sealNumber', 
        minWidth: 100,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'sealNumber');
          return true; 
        },
      },
      { 
        headerName: 'Număr pachete', 
        field: 'packageNumber', 
        minWidth: 100,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'packageNumber');
          return true; 
        },
      },
      { 
        headerName: 'MRN', 
        field: 'mrnNumber', 
        minWidth: 140,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'mrnNumber');
          return true; 
        },
      },
      { 
        headerName: 'Număr RI', 
        field: 'riNumber', 
        minWidth: 140,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'riNumber');
          return true; 
        },
      },
      { 
        headerName: 'Număr vamal', 
        field: 'customsNumber', 
        minWidth: 140,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'customsNumber');
          return true; 
        },
      },
      { 
        headerName: 'Număr mașina/CFR sosire', 
        field: 'arrivalCarNumber', 
        minWidth: 140,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'arrivalCarNumber');
          return true; 
        },
      },
      { 
        headerName: 'Număr mașina/CFR plecare', 
        field: 'departureCarNumber', 
        minWidth: 140,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'departureCarNumber');
          return true; 
        },
      },
      { 
        headerName: 'RfTemp', 
        field: 'reeferTemp', 
        minWidth: 100,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'reeferTemp');
          return true; 
        },
      },
      { 
        headerName: 'ImdgDetails', 
        field: 'imoDetails', 
        minWidth: 100,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'imoDetails');
          return true; 
        },
      },
      { 
        headerName: 'OOG DIMS', 
        field: 'oogDims', 
        minWidth: 100,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'oogDims');
          return true; 
        },
      },
      { 
        headerName: 'Category', 
        field: 'category', 
        minWidth: 100,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'category');
          return true; 
        },
      },
      { 
        headerName: 'CARGO TYPE', 
        field: 'cargoType', 
        minWidth: 100,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'cargoType');
          return true; 
        },
      },
      { 
        headerName: 'REMARKS', 
        field: 'remarks', 
        minWidth: 100,
        editable: true,
        cellClass: 'group', 
        cellRenderer: CellRenderer,
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'remarks');
          return true; 
        },
      },
      { 
        headerName: 'IMO?', 
        field: 'dangerCargo', 
        minWidth: 70,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'dangerCargo', true);
          return true; 
        },
      },
      { 
        headerName: 'SOC?', 
        field: 'soc', 
        minWidth: 70,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        valueSetter: (params : ValueSetterParams) => {
          modificaCelula(params, 'soc', true);
          return true; 
        },
      }
    ], [booking.containers, booking.bookingBills]);

    const modificaCelula = async (params : any, coloana : string, isBool : boolean = false) => {
      // @ts-ignore
      params.data[coloana] = params.newValue ? params.newValue : isBool ? params.newValue : '';
      
      setBooking(prev => ({
        ...prev,
        containers: prev.containers!.map((container, index) =>
          index === params.node.rowIndex
            ? { ...container, [coloana]: params.newValue ? params.newValue : isBool ? params.newValue : '' }
            : container
        )
      }));
      successMessage('Container editat cu succes!');
    }

  const [tableKey, setTableKey] = useState(0);

  useEffect(() => {
    setTableKey(prevKey => prevKey + 1);
  }, [booking.containers!.length]);

  return (
    <div className="ag-theme-quartz">
        <AgGridReact 
          key={`tabel-${tableKey}`}
          localeText={AG_GRID_LOCALE_RO}
          columnDefs={columns} 
          rowData={booking.containers}
          defaultColDef={{ flex: 1, minWidth: 2 }}
          domLayout='autoHeight'
          pagination={false}
          gridOptions={{rowHeight: 35}}
          enableRangeSelection={true}
          suppressClickEdit={true}
          stopEditingWhenCellsLoseFocus={true}
        />
    </div>
  );
};

export default ContainersTable;